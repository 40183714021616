import { createStore, createHook } from "react-sweet-state";
import client from "../client";
import { message } from "antd";

const Store = createStore({
  initialState: { isResetting: false },
  actions: {
    reset: () => ({ setState, getState }) => {
      // if (getState().isResetting) {
      //   return;
      // }
      setState({ isResetting: true });
      client.resetStore().then(() => {
        message.success("Refreshed");
        setState({ isResetting: false });
      });
    },
  },
});

export const useClientResetStore = createHook(Store);
export const useClientResetActions = createHook(Store, { selector: null });
