import React from "react";
import { useChunkActions } from "../stores/ChunkStore";
import { Button } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/react-hooks";
import { GetOneTaskFromCache, UPDATE_TASK } from "../data/queries";
import { moveTaskHelper } from "../helpers";

export default function TaskQuickRescheduleButton(props) {
  const [updateTask] = useMutation(UPDATE_TASK);
  const { date, label, taskId } = props;
  const cachedTask = GetOneTaskFromCache(taskId);
  const [, chunkActions] = useChunkActions();

  const onClick = () => {
    moveTaskHelper({
      sourceInfoObj: chunkActions.getChunkFromTask(cachedTask),
      destInfoObj: chunkActions.getChunkOfSection({
        sectionType: "dateRange",
        date: date.format("YYYY-MM-DD"),
      }),
      destPos: 0,
      origTask: cachedTask,
      moveMutation: updateTask,
    });
  };
  return (
    <Button size="small" icon={<ArrowRightOutlined />} onClick={onClick}>
      {label}
    </Button>
  );
}
