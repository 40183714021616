import React, { useState, useEffect } from "react";
import { Draggable } from "react-beautiful-dnd";
import Task from "./Task";
// import moment from "moment-timezone";
import TaskInline from "./TaskInline";
import { formatDuration } from "../helpers";
import pluralize from "pluralize";
import styled, { css } from "styled-components";
import { Droppable } from "react-beautiful-dnd";
import moment from "moment-timezone";

// const getItemStyle = (isDragging, draggableStyle) => ({
//   userSelect: "none",
//   ...draggableStyle,
// });

// margin: ${(props) => `${props.theme.grid.quarter}px 0`};

const StyledChunk = styled.div`
  border-radius: 4px;
  border: 2px solid ${(props) =>
    props.chunkState === CURRENT_STATE
      ? props.theme.colors.B200
      : props.theme.colors.N40};
  border-radius: ${(props) => props.theme.borderRadius}
  padding: ${(props) =>
    `${props.theme.grid.quarter}px ${props.theme.grid.quarter}px 0 ${props.theme.grid.quarter}px`};


  ${(props) => {
    if (props.isDraggingOver) {
      return css`
        border: 2px solid ${(props) => props.theme.colors.G200};
      `;
    } else {
      return css``;
    }
  }}
`;

const StyledChunkDuration = styled.div`
  display: flex;
  color: ${(props) =>
    props.isDraggingOver
      ? props.theme.colors.N0
      : props.elapsedPerc > 0
      ? props.theme.colors.B400
      : props.theme.colors.N200};
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: ${(props) =>
    props.isDraggingOver ? props.theme.colors.G200 : props.theme.colors.N40};
  padding: ${(props) =>
    `${props.theme.grid.quarter}px ${props.theme.grid.half}px`};
  margin: ${(props) =>
    `-${props.theme.grid.quarter}px -${props.theme.grid.quarter}px ${props.theme.grid.quarter}px -${props.theme.grid.quarter}px`};

  ${(props) =>
    !props.isDraggingOver && props.elapsedPerc > 0
      ? `
      background: -webkit-linear-gradient(left, ${props.theme.colors.B75} ${
          props.elapsedPerc * 100
        }%, ${props.theme.colors.B50} 0%);
      background: -moz-linear-gradient(left, ${props.theme.colors.B75} ${
          props.elapsedPerc * 100
        }%, ${props.theme.colors.B50} 0%);
      background: -ms-linear-gradient(left, ${props.theme.colors.B75} ${
          props.elapsedPerc * 100
        }%, ${props.theme.colors.B50} 0%);
      background: linear-gradient(left, ${props.theme.colors.B75} ${
          props.elapsedPerc * 100
        }%, ${props.theme.colors.B50} 0%);
      `
      : ``}

  div:last-child {
    margin: 0 0 0 auto;
  }
`;

const PAST_STATE = "past";
const CURRENT_STATE = "current";
const FUTURE_STATE = "future";

const getChunkState = (startDateTime, endDateTime) => {
  let state = FUTURE_STATE;
  if (moment().isAfter(startDateTime)) {
    if (moment().isBefore(endDateTime)) {
      // if (moment().isBefore(moment(endDateTime).endOf("day"))) {
      state = CURRENT_STATE;
    } else state = PAST_STATE;
  }
  return state;
};

const getChunkProgress = (startDateTime, endDateTime) => {
  const minsToNow = moment(startDateTime).diff(moment(), "minutes");
  const minsToEnd = moment(startDateTime).diff(moment(endDateTime), "minutes");

  // return minsToNow / minsToEnd;
  return Math.round((minsToNow / minsToEnd + Number.EPSILON) * 100) / 100;
};

export default function DraggableList(props) {
  const {
    items,
    isCreateDisabled,
    startDateTime,
    endDateTime,
    chunkId,
    chunkInfo,
    duration,
    hideDuration,
    showTitleAsDuration,
    isDropDisabled,
    showScheduledDate,
    title,
  } = props;

  let itemList = [];
  // let latestTaskPos = -1;

  const [chunkState, setChunkState] = useState(
    getChunkState(startDateTime, endDateTime)
  );
  const [progressState, setProgressState] = useState(
    getChunkProgress(startDateTime, endDateTime)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setChunkState(getChunkState(startDateTime, endDateTime));
      setProgressState(getChunkProgress(startDateTime, endDateTime));
    }, 30 * 1000); // 60 seconds
    return () => clearInterval(interval);
  }, [setChunkState, setProgressState, startDateTime, endDateTime]);

  if (items.length) {
    itemList = items.map((item, index) => {
      // latestTaskPos = index;
      return (
        <React.Fragment key={item._id}>
          {!isCreateDisabled && (
            <TaskInline
              localPos={index}
              scheduledDate={startDateTime}
              applyFields={chunkInfo.applyFields}
              sectionType={chunkInfo.sectionType}
              chunkInfo={chunkInfo}
            />
          )}
          <Draggable
            key={item._id}
            draggableId={item._id}
            index={index}
            isDragDisabled={item.isOptimistic || chunkInfo.isDragDisabled}
          >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <Task
                  key={item.id}
                  task={item}
                  showScheduledDate={showScheduledDate}
                  isDragging={snapshot.isDragging}
                />
              </div>
            )}
          </Draggable>
        </React.Fragment>
      );
    });
  } else if (isCreateDisabled) {
    itemList = (
      <div
        style={{
          color: "silver",
          fontStyle: "italic",
          minHeight: 33,
          paddingTop: 10,
        }}
      >
        Nothing to do.&nbsp;&nbsp;
        <span role="img" aria-label="party">
          🎉
        </span>
      </div>
    );
  }

  return (
    <Droppable
      droppableId={chunkId}
      key={chunkId}
      isDropDisabled={isDropDisabled}
    >
      {(provided, snapshot) => {
        return (
          <StyledChunk
            ref={provided.innerRef}
            isDraggingOver={snapshot.isDraggingOver}
            chunkState={chunkState}
          >
            <ChunkDuration
              isDraggingOver={snapshot.isDraggingOver}
              hideDuration={hideDuration}
              showTitleAsDuration={showTitleAsDuration}
              title={title}
              duration={duration}
              startDateTime={startDateTime}
              endDateTime={endDateTime}
              numTasks={items.length}
              chunkProgress={chunkState === CURRENT_STATE && progressState}
            />

            {itemList}
            {provided.placeholder}
            {!isCreateDisabled && (
              <TaskInline
                key="lastInline"
                localPos={items.length}
                scheduledDate={startDateTime}
                applyFields={chunkInfo.applyFields}
                isLast={true}
                sectionType={chunkInfo.sectionType}
                chunkInfo={chunkInfo}
              />
            )}
          </StyledChunk>
        );
      }}
    </Droppable>
  );
}

function ChunkDuration(props) {
  const {
    isDraggingOver,
    startDateTime,
    endDateTime,
    duration,
    hideDuration,
    numTasks,
    chunkProgress,
    showTitleAsDuration,
    title,
  } = props;

  return (
    <StyledChunkDuration
      isDraggingOver={isDraggingOver}
      elapsedPerc={chunkProgress}
    >
      <div>
        {!hideDuration &&
          duration &&
          `${moment(startDateTime).format("h:mmA")} - ${moment(
            endDateTime
          ).format("h:mmA")} (${formatDuration(duration)})`}
        {showTitleAsDuration && title}
      </div>
      <div>{`${numTasks} ${pluralize("task", numTasks)}`}</div>
    </StyledChunkDuration>
  );
}
