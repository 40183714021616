import { createStore, createHook } from "react-sweet-state";
import { combineItems } from "../helpers";
import moment from "moment-timezone";
import taskFilterFuncs from "../data/taskfilterfuncs";

const Store = createStore({
  initialState: { chunks: [] },
  actions: {
    add: (chunk) => ({ setState, getState }) => {
      setState({ chunks: getState().chunks.push(chunk) });
    },
    set: (chunks) => ({ setState }) => {
      setState({ chunks: chunks });
    },
    reset: () => ({ setState, getState }) => {
      setState({ chunks: [] });
    },
    setFromCols: (cols) => ({ setState }) => {
      if (!cols) return [];
      let chunks = [];

      cols.forEach((c) => {
        c.sections.forEach((s) => {
          if (s.sectionType === "inbox") {
            let inboxChunk = {
              applyFields: { scheduledDate: null },
              sectionType: s.sectionType,
              sectionId: s.id,
            };
            inboxChunk.chunkId = JSON.stringify(inboxChunk);
            inboxChunk.id = JSON.stringify(inboxChunk);
            chunks.push(inboxChunk);
          } else if (s.sectionType === "done") {
            let doneChunk = {
              applyFields: { isDone: true },
              sectionType: s.sectionType,
              isDragDisabled: s.isDragDisabled,
              sectionId: s.id,
            };
            doneChunk.chunkId = JSON.stringify(doneChunk);
            doneChunk.id = JSON.stringify(doneChunk);
            chunks.push(doneChunk);
          } else if (s.sectionType === "dateRange") {
            chunks.push(...determineChunks(s));
          }
        });
      });
      setState({ chunks });
    },
    getChunkFromTask: (task) => ({ getState }) => {
      return getState()
        .chunks.filter((c) => !c._id)
        .find((c) => taskFilterFuncs(c)(task));
    },
    getChunkOfSection: ({ sectionType, date, useLast }) => ({ getState }) => {
      let chunks = getState().chunks;
      if (useLast) chunks = chunks.reverse();

      return chunks
        .filter((c) => !c._id)
        .find((c) => {
          if (sectionType === "dateRange") {
            return (
              c.sectionType === sectionType &&
              moment(c.startDateTime).isSame(date, "day")
            );
          } else return c.sectionType === sectionType;
        });
    },
    getCurrentChunk: () => ({ getState }) => {
      let chunks = getState().chunks;
      return chunks.find((c) =>
        moment().isBetween(c.startDateTime, c.endDateTime, "[]")
      );
    },
  },
});

export const useChunkStore = createHook(Store);
export const useChunkActions = createHook(Store, { selector: null });

const getChunksbySection = (state, props) => ({
  chunks: state.chunks.filter((c) => c.sectionId === props.sectionId),
});

export const useChunksBySection = createHook(Store, {
  selector: getChunksbySection,
});

function determineChunks({
  startDateTime,
  endDateTime,
  events,
  sectionType,
  id,
}) {
  let isFirst = true;
  let lastEndTime = startDateTime;
  const dayStartDateTime = moment(startDateTime).startOf("day").format();
  const dayEndDateTime = moment(endDateTime).endOf("day").format();
  let prevChunkEndTime;
  let chunks = [];
  let sortedEvents = combineItems({
    events: events,
  });

  sortedEvents.forEach((event) => {
    if (
      !moment(lastEndTime).isSame(event.startDateTime, "minute") &&
      moment(event.startDateTime).isAfter(lastEndTime)
    ) {
      let currentChunk = {
        sectionId: id,
        sectionType: sectionType,
        startDateTime: moment(lastEndTime).format(),
        endDateTime: moment(event.startDateTime).format(),
        duration: moment(event.startDateTime).diff(lastEndTime, "minutes"),
        isFirst: isFirst,
        isLast: false,
        applyFields: { scheduledDate: moment(lastEndTime).format() },
        filterStartDateTime: isFirst ? dayStartDateTime : prevChunkEndTime,
        filterEndDateTime: moment(event.startDateTime).format(),
      };

      chunks.push(currentChunk);
      isFirst = false;
      prevChunkEndTime = currentChunk.endDateTime;
    }
    event.sectionId = id;
    chunks.push(event);
    lastEndTime = event.endDateTime;
  });

  if (
    !moment(lastEndTime).isSame(endDateTime, "minute") &&
    moment(endDateTime).isAfter(lastEndTime)
  ) {
    let currentChunk = {
      sectionId: id,
      sectionType: sectionType,
      applyFields: { scheduledDate: moment(lastEndTime).format() },
      startDateTime: moment(lastEndTime).format(),
      endDateTime: moment(endDateTime).format(),
      duration: moment(endDateTime).diff(lastEndTime, "minutes"),
      isFirst: isFirst,
      isLast: false,
      filterStartDateTime: isFirst ? dayStartDateTime : prevChunkEndTime,
      filterEndDateTime: moment(endDateTime).format(),
    };

    chunks.push(currentChunk);
  }

  let index = chunks
    .slice()
    .reverse()
    .findIndex((c) => !c._id); // find the last taskChunk
  let count = chunks.length - 1;
  let finalIndex = index >= 0 ? count - index : index;

  if (finalIndex >= 0) {
    chunks[finalIndex].isLast = true;
    chunks[finalIndex].filterEndDateTime = dayEndDateTime;
  }

  chunks.forEach((c, i) => {
    if (!c._id) {
      c.chunkId = JSON.stringify(c);
      c.id = JSON.stringify(c);
    }
  });

  return chunks;
}
