import React, { useEffect } from "react";
import styled from "styled-components";
import Spinner from "./Spinner";
import { useMutation } from "@apollo/react-hooks";
import { Link } from "react-router-dom";
import media from "../styles/media-queries";

import { useQuery } from "@apollo/react-hooks";
import {
  GET_LINK,
  DELETE_LINK,
  RemoveLinkFromCache,
} from "../queries/LinkQueries";

import LinkInlineField from "./LinkInlineField";

import { Button } from "antd";
import {
  LinkOutlined,
  NumberOutlined,
  AlignLeftOutlined,
  StarOutlined,
  SnippetsOutlined,
  MessageOutlined,
} from "@ant-design/icons";

import LinkCheckbox from "./LinkCheckbox";
import LinkQuotes from "./LinkQuotesList";
import { wordsToMins } from "../helpers";
import StarRating from "./StarRating";

const StyledLinkOverviewTitle = styled.div`
  width: 100%;
  textarea {
    font-family: "Markazi Text", serif;
    font-size: 30px;
    line-height: 30px;
  }
`;

const StyledExcerpt = styled.div`
  width: 100%;
`;
const IconColumn = styled.div`
  margin-top: 3px;
  flex: 0 0 30px;
`;
const OverviewRow = styled.div`
  display: flex;
  margin-bottom: ${(props) => props.theme.grid.half}px;
`;

const StyledLinkOverview = styled.div`
  padding: ${(props) => `${props.theme.grid.half}px`};
`;
const StyledLinkUrl = styled.div`
  margin-bottom: ${(props) => `${props.theme.grid.quarter}px`};
  word-break: break-all;
`;
const StyledActions = styled.div`
  margin-top: ${(props) => props.theme.grid.full}px;
  button + button {
    margin-left: ${(props) => `${props.theme.grid.quarter}px`}};
  }
`;
const StyledButton = styled(Button)`
  margin-left: auto;
`;

const StyledLinkOverviewMobileControls = styled.div`
  margin-bottom: ${(props) => props.theme.grid.full}px;
  display: none;
  ${media.tablet`display: block;`}
`;

const StyledWordCount = styled.div`
  p {
    margin-bottom: 0;
  }
`;

export default function LinkOverview(props) {
  const {
    // onCancel,
    modalProps: { _id },
  } = props;
  const { loading, error, data } = useQuery(GET_LINK, {
    variables: { _id: _id },
    // fetchPolicy: "network-only"
  });
  const [deleteLink] = useMutation(DELETE_LINK, {
    update(cache, { data: { deleteLink } }) {
      RemoveLinkFromCache(deleteLink);
    },
  });

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = null;
    };
  });
  if (error) return error;
  if (loading) return <Spinner />;
  const link = data.link || {};
  if (!link) return "Something went wrong";
  return (
    <StyledLinkOverview>
      <StyledLinkOverviewMobileControls>
        <Link to="/read">
          <Button icon="left">Back</Button>
        </Link>
      </StyledLinkOverviewMobileControls>
      <OverviewRow>
        <IconColumn style={{ marginTop: 6 }}>
          <LinkCheckbox
            _id={_id}
            status={link.status}
            isOptimistic={link.isOptimistic}
          />
        </IconColumn>
        <LinkInlineField
          _id={_id}
          fieldName="title"
          WrapComponent={StyledLinkOverviewTitle}
          isLoading={loading}
          submitOnBlur={true}
        />
      </OverviewRow>
      <OverviewRow>
        <IconColumn>
          <LinkOutlined />
        </IconColumn>
        <StyledLinkUrl>
          <a href={link.url}>{link.url}</a>
        </StyledLinkUrl>
      </OverviewRow>
      <OverviewRow>
        <IconColumn>
          <NumberOutlined />
        </IconColumn>
        <StyledWordCount>
          <LinkInlineField
            _id={_id}
            fieldName="wordCount"
            WrapComponent={StyledExcerpt}
            isLoading={loading}
            submitOnBlur={true}
          />
          <p>{wordsToMins(link.wordCount)} min read</p>
        </StyledWordCount>
      </OverviewRow>
      <OverviewRow>
        <IconColumn>
          <AlignLeftOutlined />
        </IconColumn>
        <LinkInlineField
          _id={_id}
          fieldName="excerpt"
          WrapComponent={StyledExcerpt}
          isLoading={loading}
          submitOnBlur={true}
          placeholder="No excerpt"
        />
      </OverviewRow>
      <OverviewRow>
        <IconColumn>
          <StarOutlined />
        </IconColumn>
        <StarRating _id={_id} value={link.rating} name="rating" />
      </OverviewRow>
      <OverviewRow>
        <IconColumn>
          <SnippetsOutlined />
        </IconColumn>
        <LinkInlineField
          _id={_id}
          fieldName="notes"
          WrapComponent={StyledExcerpt}
          isLoading={loading}
          submitOnBlur={true}
          placeholder="No notes"
        />
      </OverviewRow>
      <OverviewRow>
        <IconColumn>
          <MessageOutlined />
        </IconColumn>
        <LinkQuotes linkId={_id} quotes={link.quotes} />
      </OverviewRow>
      <StyledActions>
        <StyledButton
          type="danger"
          onClick={() => {
            // setIsEditMode(false);
            deleteLink({
              variables: { _id: _id },
              optimisticResponse: {
                deleteLink: link,
              },
            });
          }}
        >
          Delete
        </StyledButton>
      </StyledActions>
    </StyledLinkOverview>
  );
}
