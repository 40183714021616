import React from "react";
import "normalize.css";
import LoginForm from "../components/LoginForm";
import Nav from "../components/Nav";
import PageHeader from "../components/PageHeader";
import styled from "styled-components";
import media from "../styles/media-queries";
import { useUserStore } from "../stores/UserStore";
import KeyboardShortcuts from "../components/KeyboardShortcuts";

import "../App.css";
import KeyboardShortcutsTask from "../components/KeyboardShortcutsTask";
import { Tag } from "antd";

const StyledPageLayout = styled.div``;
// padding: 0 0 20px 0;
// grid-auto-columns: 400px;
const StyledPageBody = styled.div`
  padding-top: 50px;
  display: inline-grid;
  margin: 0 ${(props) => props.theme.grid.full}px;

  grid-auto-flow: column;
  grid-gap: 20px;
  ${media.desktop`
    display: block;
    height:auto;
    padding: 0;
  > div + div {
    margin-left: 0;
  }
`};
`;

// grid-gap: 20px;

// > div + div {
//   margin-left: 20px;
// }

export default function TaskLayoutPage(props) {
  const [userStoreState] = useUserStore();

  if (!userStoreState.isLoggedIn) return <LoginForm />;

  return (
    <StyledPageLayout>
      <PageHeader title={`Hey, ${localStorage.getItem("firstName")} 👋`}>
        <Nav />
        {JSON.parse(localStorage.getItem("devMode")) && (
          <Tag color="purple" style={{ margin: "0 auto" }}>
            DEV MODE
          </Tag>
        )}
      </PageHeader>
      <StyledPageBody>{props.children}</StyledPageBody>
      <KeyboardShortcuts />
      <KeyboardShortcutsTask />
    </StyledPageLayout>
  );
}
