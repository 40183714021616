import React from "react";
import styled from "styled-components";
import moment from "moment-timezone";

import LinkQuoteDeleteButton from "./LinkQuoteDeleteButton";

const StyledLinkQuote = styled.li`
  background-color: ${props => props.theme.colors.N20};
  border-radius: 4px;
  padding: ${props => props.theme.grid.quarter}px;

  & + & {
    margin-top: ${props => props.theme.grid.quarter}px;
  }

  blockquote {
    margin: 0;
    font-style: italic;
    border-left: 2px solid ${props => props.theme.colors.N80};
    padding: ${props => props.theme.grid.tiny}px
      ${props => props.theme.grid.half}px;
  }
`;

const StyledLinkQuoteMeta = styled.div`
  font-size: 12px;
  margin-top: ${props => props.theme.grid.quarter}px;
  text-align: right;
  color: ${props => props.theme.colors.N80};
`;

export default function LinkQuoteItem(props) {
  const { _id, quote, createdAt, isOptimistic } = props;

  return (
    <StyledLinkQuote>
      <blockquote>
        {quote}
        <StyledLinkQuoteMeta>
          <span>
            &mdash; {moment(createdAt).format("MMMM Do YYYY, h:mm:ss a")}
          </span>
          <LinkQuoteDeleteButton _id={_id} isLoading={isOptimistic} />
        </StyledLinkQuoteMeta>
      </blockquote>
    </StyledLinkQuote>
  );
}
