import { createStore, createHook } from "react-sweet-state";

const Store = createStore({
  initialState: { openInlineId: null },
  actions: {
    open: (inlineId) => ({ setState, getState }) => {
      setState({ openInlineId: inlineId });
      console.log(`Opening: ${getState().openInlineId}`);
    },
    close: (inlineId) => ({ setState, getState }) => {
      const currentlyOpenInlineId = getState().openInlineId;
      console.log(`Closing: ${currentlyOpenInlineId}`);
      if (inlineId === currentlyOpenInlineId) {
        setState({ openInlineId: null });
      }
    },
  },
});

export const useInlineStore = createHook(Store);

export const useInlineActions = createHook(Store, { selector: null });
