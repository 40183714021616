import React from "react";
import styled from "styled-components";
import { PlusOutlined } from "@ant-design/icons";
import { TaskInlineFormWrapper } from "./TaskForm";
import { useInlineStore } from "../stores/InlineStore";

const StyledLine = styled.div`
  margin-left: -10px;
`;
const StyledInlineButton = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.colors.G400};
  border-radius: 3px;
  color: white;
  display: flex;
  height: 20px;
  justify-content: center;
  opacity: 0;
  transform: translate(-10px, 0px);
  transition: all 0.2s ease;
  width: 20px;
  transition-delay: 0;
`;

const StyledLastInline = styled.div`
  align-items: center;
  border: 1px solid transparent;
  border-radius: 4px;
  width: 100%;
  display: flex;
  transition: all 0.2s ease;
  margin-bottom: ${(props) => props.theme.grid.quarter}px;

  padding: ${(props) => props.theme.grid.quarter}px
    ${(props) => props.theme.grid.half}px;

  &:hover {
    background-color: ${(props) => props.theme.contextBg};
  }
`;
const StyledLastText = styled.div`
  color: ${(props) => props.theme.eventExpandTextColor};
  display: inline-block;
  i {
    margin-left: ${(props) => props.theme.grid.quarter}px;
  }
`;
const StyledLastCheckbox = styled.div`
  height: 16px;
  width: 16px;
  border: 1px solid ${(props) => props.theme.taskBorderHover};
  display: inline-block;
  margin-right: ${(props) => props.theme.grid.half}px;
  border-radius: 2px;
`;

// &:last-of-type:not(:first-of-type) {
//   margin-top:4px;
// }

const StyledTaskInline = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.2s ease;


  ${(props) => {
    if (props.isExpanded) {
      return `
        height:auto;
        margin: ${props.theme.grid.quarter}px 0;
      `;
    } else if (props.isLast) {
      return `
        margin: ${props.theme.grid.quarter}px 0 0 0 ;
        height:auto;
      `;
    } else {
      return `
        height: 8px;
        margin: -6px 0 -2px 0;
        position:relative;
        z-index: 99;
      `;
    }
  }}
  ${StyledLine} {
    width: 100%;
    height: 2px;
    transition: all 0.2s ease;
    transition-delay: 0;
  }

  &:hover {
    > ${StyledLine} {
      transition-delay: 150ms;
      background-color: ${(props) => props.theme.colors.G400};
    }
    ${StyledInlineButton} {
      transition-delay: 150ms;
      opacity:1;
    }}
  }
`;

export default function TaskInline(props) {
  const {
    isLast,
    localPos,
    applyFields,
    scheduledDate,
    hide,
    sectionType,
    chunkInfo,
  } = props;
  const pos = isLast ? "last" : localPos;
  const [inlineStoreState, inlineStoreActions] = useInlineStore();

  let inlineId = `${scheduledDate || sectionType}_${pos}`;
  let contents; // = <React.Fragment />;
  if (inlineStoreState.openInlineId === inlineId) {
    contents = (
      <TaskInlineFormWrapper
        inlineId={inlineId}
        localPos={localPos}
        applyFields={applyFields}
        chunkInfo={chunkInfo}
      />
    );
  } else if (hide) {
    contents = <React.Fragment />;
  } else if (isLast) {
    contents = (
      <StyledLastInline>
        <StyledLastCheckbox />
        <StyledLastText>Add new task</StyledLastText>
      </StyledLastInline>
    );
  } else {
    contents = (
      <>
        <StyledInlineButton>
          <PlusOutlined />
        </StyledInlineButton>
        <StyledLine />
      </>
    );
  }
  return (
    <StyledTaskInline
      onClick={() => {
        inlineStoreActions.open(inlineId);
      }}
      isExpanded={inlineStoreState.openInlineId === inlineId}
      isLast={isLast}
    >
      {contents}
    </StyledTaskInline>
  );
}
