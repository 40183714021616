import React from "react";
import styled from "styled-components";
import Linkify from "linkifyjs/react";
import EventResponseIcon from "./EventResponseIcon";

const StyledEventAttendee = styled.li`
  display: block;
  color: ${(props) => `${props.theme.eventTextColor}`};
  cursor: default;
  background-color: ${(props) => `${props.theme.attendeeBg}`};
  border-radius: ${(props) => `${props.theme.borderRadius}`};
  padding: ${(props) => `${props.theme.grid.tiny}px 0px`};

  & + & {
    margin-top: ${(props) => `${props.theme.grid.tiny}px`};
  }
`;

const StyledIcon = styled.div`
  margin-right: ${(props) => `${props.theme.grid.quarter}px`};
  display: inline-block;
  ${(props) => {
    switch (props.responseStatus) {
      case "accepted":
        return `color: ${props.theme.eventAcceptedColor}`;
      case "declined":
        return `color: ${props.theme.eventDeclinedColor}`;
      default:
    }
  }}
`;

const StyledOrganizerIcon = styled.div`
  display: inline-block;
  margin-left: ${(props) => `${props.theme.grid.quarter}px`};
`;

export default function EventAttendee(props) {
  const { attendee, linkify } = props;
  const displayName = attendee.displayName || attendee.email;
  const organizerIcon = attendee.organizer && (
    <StyledOrganizerIcon>(Organiser)</StyledOrganizerIcon>
  );

  return (
    <StyledEventAttendee title={attendee.email}>
      <StyledIcon responseStatus={attendee.responseStatus}>
        <EventResponseIcon status={attendee.responseStatus} />
      </StyledIcon>
      {linkify ? <Linkify>{displayName}</Linkify> : displayName}
      {organizerIcon}
    </StyledEventAttendee>
  );
}
