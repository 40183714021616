import React, { useEffect } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import {
  MOVE_TASK,
  // QueryDays,
  GET_SET_BY_RANGE,
  GetOneTaskFromCache,
  // GET_TASKS_BY_RANGE,
  // GET_EVENTS_BY_RANGE,
  // GET_DAILY_HABITS_BY_RANGE,
  // TaskHelpers,
  // GetOneSetFromCache,
} from "../data/queries";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import media from "../styles/media-queries";
import { useWeekdayStore } from "../stores/WeekdayStore";
import { useChunkActions } from "../stores/ChunkStore";
// import { useShowDoneTasksStore } from "../stores/ShowDoneTasksStore";
import { useQuery, useMutation } from "@apollo/react-hooks";
// import client from "../client";
import Day from "./Day";
import DayGroup from "./DayGroup";
// import HomeDoneTasks from "./HomeDoneTasks";
import moment from "moment-timezone";
import { moveTaskHelper, combineItems } from "../helpers";

// flex: 1;
// overflow-y: scroll
// height: calc(100vh - 66px);
const StyledHomeCol = styled.div`
  padding-right: 20px;
  & + & {
    margin-top: 0;
  }
  ${media.desktop`
    & + & {
      margin-top: ${(props) => `${props.theme.grid.full}px`};
    }
  `};
`;

export default function Home(props) {
  const [weekdayStoreState, weekdayStoreActions] = useWeekdayStore();
  const [, chunkStoreActions] = useChunkActions();
  // const [showDoneState] = useShowDoneTasksStore();

  useEffect(() => {
    const interval = setInterval(() => {
      weekdayStoreActions.checkReset();
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, [weekdayStoreActions]);

  const [moveTask] = useMutation(MOVE_TASK);

  const { data, error, networkStatus } = useQuery(GET_SET_BY_RANGE, {
    variables: {
      startDate: weekdayStoreState.weekDays[0],
      endDate:
        weekdayStoreState.weekDays[weekdayStoreState.weekDays.length - 1],
      timezone: moment.tz.guess(),
    },
    notifyOnNetworkStatusChange: true,
    pollInterval: 300000,
  });

  let futureDays = [...weekdayStoreState.weekDays];

  futureDays.shift();

  const dateFormat = "dddd, MMMM Do";

  const { tasks, events, allDayEvents, dailyHabits } = data
    ? data.setByRange
    : {};

  const cols = [
    {
      title: "Inbox",
      sections: [
        {
          id: "earlier",
          sectionType: "dateRange",
          title: "Earlier",
          startDateTime: new Date(0),
          endDateTime: moment(weekdayStoreState.weekDays[0])
            .subtract(1, "days")
            .endOf("day")
            .format(),
          hideIfEmpty: true,
          isDropDisabled: true,
          isCreateDisabled: true,
          hideDuration: true,
          hideTitle: true,
          showTitleAsDuration: true,
        },
        {
          id: "inbox",
          sectionType: "inbox",
          title: "Inbox",
          hideTitle: true,
          hideDuration: false,
          showTitleAsDuration: true,
        },
      ],
    },
    {
      title: moment(weekdayStoreState.weekDays[0]).format(dateFormat),
      isToday: true,
      sections: [
        {
          id: weekdayStoreState.weekDays[0],
          sectionType: "dateRange",
          title: moment(weekdayStoreState.weekDays[0]).format(dateFormat),
          hideTitle: true,
          events: events
            ? events.filter((e) =>
                moment(e.startDateTime).isSame(
                  weekdayStoreState.weekDays[0],
                  "day"
                )
              )
            : [],
          startDateTime: moment(weekdayStoreState.weekDays[0])
            .hour(8)
            .minute(0)
            .seconds(0)
            .tz("Australia/Sydney")
            .toDate(),
          endDateTime: moment(weekdayStoreState.weekDays[0])
            .hour(17)
            .minute(30)
            .seconds(0)
            .tz("Australia/Sydney")
            .toDate(),
        },
      ],
    },
    // {
    //   title: "Upcoming",
    //   sections: futureDays.map((fd) => {
    //     return {
    //       id: fd,
    //       sectionType: "dateRange",
    //       title: moment(fd).format(dateFormat),
    //       events: events
    //         ? events.filter((e) => moment(e.startDateTime).isSame(fd, "day"))
    //         : [],
    //       startDateTime: moment(fd)
    //         .hour(7)
    //         .minute(30)
    //         .seconds(0)
    //         .tz("Australia/Sydney")
    //         .toDate(),
    //       endDateTime: moment(fd)
    //         .hour(17)
    //         .minute(0)
    //         .seconds(0)
    //         .tz("Australia/Sydney")
    //         .toDate(),
    //     };
    //   }),
    // },
  ];

  futureDays.forEach((fd, i) => {
    cols.push({
      title: moment(fd).format(dateFormat),
      isCollapsed: [6,7].includes(moment(fd).isoWeekday()),
      sections: [
        {
          id: fd,
          sectionType: "dateRange",
          title: moment(fd).format(dateFormat),
          hideTitle: true,
          events: events
            ? events.filter((e) => moment(e.startDateTime).isSame(fd, "day"))
            : [],
          startDateTime: moment(fd)
            .hour(8)
            .minute(0)
            .seconds(0)
            .tz("Australia/Sydney")
            .toDate(),
          endDateTime: moment(fd)
            .hour(17)
            .minute(30)
            .seconds(0)
            .tz("Australia/Sydney")
            .toDate(),
        },
      ],
    });
  });

  chunkStoreActions.setFromCols(cols);

  const sortedTasks = tasks ? combineItems({ tasks }) : [];

  return (
    <>
      <Helmet>
        <title>Dailydo - Home</title>
      </Helmet>
      <DragDropContext
        onDragEnd={(result) => {
          if (result.destination && result.destination.droppableId) {
            moveTaskHelper({
              sourceInfoObj: JSON.parse(result.source.droppableId),
              destInfoObj: JSON.parse(result.destination.droppableId),
              destPos: result.destination.index,
              origTask: GetOneTaskFromCache(result.draggableId),
              moveMutation: moveTask,
            });
          }
        }}
      >
        {cols.map((col) => {
          return (
            <StyledHomeCol key={col.title}>
              <DayGroup
                title={col.title}
                isToday={col.isToday}
                error={error}
                isCollapsed={col.isCollapsed}
              >
                {col.sections.map((section) => {
                  return (
                    <Day
                      key={section.title}
                      sectionId={section.id}
                      {...section}
                      isLoading={networkStatus < 3}
                      tasks={sortedTasks}
                      events={events}
                      allDayEvents={allDayEvents}
                      dailyHabits={dailyHabits}
                    ></Day>
                  );
                })}
              </DayGroup>
            </StyledHomeCol>
          );
        })}
      </DragDropContext>
    </>
  );
}

// export default Home;
