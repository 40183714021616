import React from "react";
import { Form, Input, Button } from "antd";
import { LOGIN_USER } from "../data/queries";
import styled from "styled-components";
import { UserModifier } from "../data/modifiers";
import { Helmet } from "react-helmet";
import { useMutation } from "@apollo/react-hooks";
import { MailOutlined, LoginOutlined, LockOutlined } from "@ant-design/icons";
// import { useUserStore } from "./UserStore";

const StyledLoginPage = styled.div`
  max-width: 300px;
  margin: 0 auto;
`;

export default function LoginForm(props) {
  // const [userStoreState, userStoreActions] = useUserStore();

  const [
    loginUser,
    { loading: mutationLoading, error: mutationError }
  ] = useMutation(LOGIN_USER);
  const onFinish = values => {
    console.log("Success:", values);
    loginUser({
      variables: { email: values.email, password: values.password },
      update: (caches, { data }) => {
        UserModifier.login(data.loginUser, () => {
          window.location.reload(false); // TODO: [DD-55] remove this terribleness
        });
      }
    });
  };
  const onFinishFailed = errorInfo => {
    console.log("Failed:", errorInfo);
  };

  return (
    <StyledLoginPage>
      <Helmet>
        <title>Dailydo - Log in</title>
      </Helmet>
      <h1>Login</h1>
      <Form
        // onSubmit={e => {
        //   e.preventDefault();
        //   this.handleSubmit(loginUser);
        // }}
        name="login"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className="login-form"
      >
        <Form.Item
          name="email"
          style={{ marginBottom: 0 }}
          rules={[
            {
              required: true,
              message: "Please input your email!"
            }
          ]}
        >
          <Input
            autoFocus
            prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="Email"
            type="email"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your Password!"
            }
          ]}
        >
          <Input.Password
            prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={mutationLoading}
            icon={<LoginOutlined />}
          >
            Log in
          </Button>
        </Form.Item>
        {mutationError ? <p>Login failed</p> : <p />}
      </Form>
    </StyledLoginPage>
  );
}

// LoginForm = Form.create()(LoginForm);

// export default LoginForm;
