import React from "react";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  QuestionCircleOutlined,
  InfoCircleOutlined,
  PauseCircleFilled,
} from "@ant-design/icons";
import { lightTheme } from "../styles/themes";

export default function EventResponseIcon(props) {
  const { status } = props;
  let StatusIcon;
  // if (!status) return <React.Fragment />;
  switch (status) {
    case "accepted":
      StatusIcon = (
        <CheckCircleFilled style={{ color: lightTheme.colors.G400 }} />
      );
      break;
    case "declined":
      StatusIcon = (
        <CloseCircleFilled style={{ color: lightTheme.colors.R400 }} />
      );
      break;
    case "tentative":
      StatusIcon = <PauseCircleFilled alt="Tentative" />;
      break;
    case "needsAction":
      StatusIcon = <InfoCircleOutlined />;
      break;
    default:
      StatusIcon = <QuestionCircleOutlined />;
      break;
  }

  return StatusIcon;
}
