import React from "react";
import styled from "styled-components";
import { useHoveredTaskActions } from "../stores/HoveredTaskStore";
import TaskCheckbox from "./TaskCheckbox";
import TaskContext from "./TaskContext";
import { useModalActions } from "../stores/ModalStore";

const StyledTaskName = styled.div`
  cursor: pointer;
  width: 100%;
  color: ${(props) =>
    props.isDone || props.isOptimistic
      ? props.theme.colors.N80
      : props.theme.dayText};
  text-decoration: ${(props) => (props.isDone ? "line-through" : "inherit")};
`;

const StyledTask = styled.div`
  background-color: white;
  border: 1px solid ${(props) => props.theme.contextBg};
  border-radius: ${(props) => props.theme.borderRadius};
  cursor: pointer;
  margin: 0 0 ${(props) => `${props.theme.grid.quarter}px`} 0;
  transition: border 0.2s, background 0.2s, opacity 0.2s, transform 0.2s,
    height 0.2s ease;
  -webkit-user-select: none;
  &:hover {
    border: 1px solid ${(props) => props.theme.taskBorderHover};
    background-color: ${(props) => props.theme.taskBgHover};
    box-shadow: 1px 1px 4px ${(props) => props.theme.taskBorderHover};

    ${StyledTaskName} {
      transition: color 0.2s ease;
    }
  }
  ${(props) =>
    props.isDragging &&
    `
      background-color: ${props.theme.taskBgHover};
      outline: none;
      box-shadow: 1px 1px 4px ${props.theme.taskBorderHover};
      transform: rotate(2deg);
  `};
`;

const StyledTaskInner = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  padding: ${(props) => props.theme.grid.quarter}px;
`;

export const StyledContext = styled.div``;

export default function Task(props) {
  const { task, showScheduledDate, isDragging } = props;
  const [, hoveredTaskActions] = useHoveredTaskActions();
  const [, modalStoreActions] = useModalActions();
  const formatName = props.task.name.replace("<-", "←").replace("->", "→");
  if (task) {
    return (
      <StyledTask
        isDragging={isDragging}
        isSoftRemove={task.isSoftRemove}
        onMouseEnter={() => {
          hoveredTaskActions.setTaskDetails({
            _id: task._id,
            isDone: task.isDone,
            scheduledDate: task.scheduledDate,
          });
        }}
        onMouseLeave={() => {
          hoveredTaskActions.clear();
        }}
        onClick={() => {
          modalStoreActions.open("taskOverview", { taskId: task._id });
        }}
      >
        <StyledTaskInner>
          <TaskCheckbox task={task} isLoading={task.isOptimistic} useSoft />
          <StyledTaskName isDone={task.isDone} isOptimistic={task.isOptimistic}>
            <span>{formatName}</span>
          </StyledTaskName>
        </StyledTaskInner>
        <TaskContext task={task} showScheduledDate={showScheduledDate} />
      </StyledTask>
    );
  } else return <React.Fragment />;
}
