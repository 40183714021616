import moment from "moment-timezone";

export default function taskFilterFuncs(
  { sectionType, filterStartDateTime, filterEndDateTime },
  filterType
) {
  if (sectionType === "inbox") {
    return (t) => !t.scheduledDate;
  } else if (sectionType === "done") {
    return (t) => t.isDone;
  } else if (sectionType === "dateRange") {
    return (t) => {
      return filterType === "day"
        ? moment(t.scheduledDate).isSame(filterStartDateTime, "day")
        : moment(t.scheduledDate).isSameOrAfter(filterStartDateTime) &&
            moment(t.scheduledDate).isBefore(filterEndDateTime);
    };
  }
}
