import React, { useEffect } from "react";
import styled from "styled-components";
import media from "../styles/media-queries";
import { useClientResetActions } from "../stores/ClientResetStore";
import DayGroup from "./DayGroup";
import Day from "./Day";
import { useQuery } from "react-apollo";
import { QueryDoneTasks } from "../data/queries";
import { Button } from "antd";
import { LoadingOutlined, ReloadOutlined } from "@ant-design/icons";
import { useChunkActions } from "../stores/ChunkStore";
import { DragDropContext } from "react-beautiful-dnd";

const StyledHomeCol = styled.div`
  flex: 1;
  overflow: auto;
  & + & {
    margin-top: 0;
  }
  ${media.desktop`
    & + & {
      margin-top: ${(props) => `${props.theme.grid.full}px`};
    }
  `};
`;

export default function DoneTasksPage(props) {
  const [, clientResetStoreActions] = useClientResetActions();
  const [, chunkStoreActions] = useChunkActions();
  const { loading, error, data, fetchMore, networkStatus } = useQuery(
    QueryDoneTasks.query,
    QueryDoneTasks
  );
  useEffect(() => {
    return () => {
      clientResetStoreActions.reset();
    };
  }, [clientResetStoreActions]);

  const cols = [
    {
      title: "Done",
      sections: [
        {
          id: "done",
          sectionType: "done",
          title: "Done",
          hideTitle: true,
          hideDuration: false,
          showTitleAsDuration: true,
          isDragDisabled: true,
          isDropDisabled: true,
          isCreateDisabled: true,
        },
      ],
    },
  ];

  chunkStoreActions.setFromCols(cols);

  return (
    <DragDropContext onDragEnd={(result) => {}}>
      <StyledHomeCol>
        <DayGroup title={cols[0].title} error={error}>
          <Day
            sectionId={cols[0].sections[0].id}
            {...cols[0].sections[0]}
            isLoading={networkStatus < 3}
            tasks={(data && data.tasks) || []}
          >
            <Button
              style={{
                display: "block",
                margin: "20px auto",
              }}
              size="small"
              icon={
                networkStatus === 3 ? <LoadingOutlined /> : <ReloadOutlined />
              }
              disabled={loading}
              onClick={() => {
                fetchMore({
                  variables: {
                    offset: data.tasks.length,
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;
                    return Object.assign({}, prev, {
                      tasks: [...prev.tasks, ...fetchMoreResult.tasks],
                    });
                  },
                });
              }}
            >
              Load More
            </Button>
          </Day>
        </DayGroup>
      </StyledHomeCol>
    </DragDropContext>
  );
}
