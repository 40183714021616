import useMousetrap from "./MousetrapHook";
import React from "react";
import { useHoveredTaskStore } from "../stores/HoveredTaskStore";
import { message } from "antd";
import moment from "moment-timezone";
import { useMutation } from "@apollo/react-hooks";
import {
  UPDATE_TASK,
  DELETE_TASK,
  RemoveTaskFromCache,
  GetOneTaskFromCache,
} from "../data/queries";
import { moveTaskHelper } from "../helpers";
import { useChunkActions } from "../stores/ChunkStore";

export default function KeyboardShortcutsTask(props) {
  const [hoveredTaskStore, hoveredTaskActions] = useHoveredTaskStore();
  const [, chunkActions] = useChunkActions();
  const [updateTask] = useMutation(UPDATE_TASK);
  const [deleteTask] = useMutation(DELETE_TASK, {
    update: (cache, { data: { deleteTask } }) => {
      RemoveTaskFromCache({
        task: deleteTask,
        chunkInfo: chunkActions.getChunkFromTask(deleteTask),
      });
    },
    optimisticResponse: {
      __typename: "Mutation",
      deleteTask: {
        _id: hoveredTaskStore._id,
        isDone: hoveredTaskStore.isDone,
        scheduledDate: hoveredTaskStore.scheduledDate,
        __typename: "DeletedTask",
      },
    },
  });

  const moveTaskShortcut = function ({ taskId, toScheduledDate, dateDesc }) {
    if (!taskId) {
      return message.warning("Not hovering over a task");
    }

    const cachedTask = GetOneTaskFromCache(taskId);

    if (cachedTask) {
      moveTaskHelper({
        sourceInfoObj: chunkActions.getChunkFromTask(cachedTask),
        destInfoObj: chunkActions.getChunkOfSection({
          sectionType: toScheduledDate ? "dateRange" : "inbox",
          date: toScheduledDate ? toScheduledDate.format("YYYY-MM-DD") : null,
        }),
        destPos: 0,
        origTask: cachedTask,
        moveMutation: updateTask,
      });
      message.success(`Moved to ${dateDesc}`);
      hoveredTaskActions.clear();
    } else {
      message.error("Task not in cache");
    }
  };

  useMousetrap("t t", () => {
    moveTaskShortcut({
      taskId: hoveredTaskStore._id,
      toScheduledDate: moment(),
      dateDesc: "today",
    });
  });
  useMousetrap("t i", () => {
    moveTaskShortcut({
      taskId: hoveredTaskStore._id,
      toScheduledDate: null,
      dateDesc: "inbox",
    });
  });
  useMousetrap("t m", () => {
    moveTaskShortcut({
      taskId: hoveredTaskStore._id,
      toScheduledDate: moment().add(1, "days").startOf("day"),
      dateDesc: "tomorrow",
    });
  });
  useMousetrap("t x x", () => {
    if (hoveredTaskStore._id) {
      deleteTask({ variables: { _id: hoveredTaskStore._id } });
      message.success(`Task deleted`);
      hoveredTaskActions.clear();
    } else {
      message.error("No task selected");
    }
  });
  return <React.Fragment />;
}
