import moment from "moment-timezone";
import pluralize from "pluralize";
import memoize from "memoize-one";
import {
  // GetOneDayFromCache,
  // GetDaysFromCache,
  GetOneTaskFromCache,
  // GetCurrentSetFromCache,
  GetOneSetFromCache,
  // SetHelpers,
  WriteSetIntoCache,
  InsertTaskIntoCache,
  RemoveTaskFromCache,
} from "./data/queries";
// import taskFilterFuncs from "./data/taskfilterfuncs";
// import client from "./client";

export const guidGenerator = () => {
  var S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return S4();
};

export const sortItemsByDateField = (items, fieldName) => {
  return [...items].sort((left, right) => {
    return moment.utc(left[fieldName]).diff(moment.utc(right[fieldName]));
  });
};

export const filterItems = memoize(
  ({
    items,
    group,
    date,
    onlyIncludeTypes,
    excludeId,
    taskDateField = "scheduledDate",
  }) => {
    const typeCheck = (typeToCheck) => {
      if (onlyIncludeTypes) {
        return onlyIncludeTypes.indexOf(typeToCheck) >= 0;
      } else return true; // include all types by default
    };

    const idCheck = (idToCheck) => {
      if (excludeId) {
        return excludeId !== idToCheck;
      } else return true; // include items with all ids
    };

    if (items) {
      let filterFunc;
      switch (group) {
        case "before":
          filterFunc = (item) => {
            return (
              typeCheck(item.__typename) &&
              idCheck(item._id) &&
              item.__typename === "Task" &&
              moment(item[taskDateField], "YYYY-MM-DD").isValid() &&
              moment(item[taskDateField]).isBefore(date, "day")
            );
          };
          break;
        case "after":
          filterFunc = (item) => {
            return (
              typeCheck(item.__typename) &&
              idCheck(item._id) &&
              item.__typename === "Task" &&
              moment(item[taskDateField], "YYYY-MM-DD").isValid() &&
              moment(item[taskDateField]).isAfter(date, "day")
            );
          };
          break;
        case "same":
          filterFunc = (item) => {
            return (
              typeCheck(item.__typename) &&
              idCheck(item._id) &&
              ((item.__typename === "Task" &&
                moment(item[taskDateField], "YYYY-MM-DD").isValid() &&
                moment(item[taskDateField]).isSame(date, "day")) ||
                (item.__typename === "Event" &&
                  moment(item.startDateTime).isSame(date, "day")))
            );
          };
          break;
        case "inbox":
          filterFunc = (item) => {
            return (
              typeCheck(item.__typename) &&
              idCheck(item._id) &&
              item.__typename === "Task" &&
              (!item[taskDateField] || item[taskDateField] === "")
            );
          };
          break;
        case "done":
          filterFunc = (item) => true; // we pass a list of done tasks to the component. no need to filter
          break;
        default:
        // code block
      }
      const filteredItems = [...items].filter(filterFunc);
      return filteredItems;
    } else return [];
  }
);

export const filterDailyHabits = memoize(({ dailyHabits, date }) => {
  if (dailyHabits && dailyHabits.length) {
    return dailyHabits.filter((dh) => moment(dh.date).isSame(date, "day"));
  } else return [];
});

export const sortTasksByLocalPos = (tasks) => {
  return tasks.sort((left, right) => {
    return left.localPos > right.localPos;
  });
};

export const formatDuration = (durationMins) => {
  return durationMins >= 60
    ? `${Math.round((durationMins / 60) * 10) / 10} ${pluralize(
        "hr",
        durationMins / 60
      )}`
    : `${durationMins} mins`;
};

export const getGreetingTime = (m) => {
  var g = null; //return g

  if (!m || !m.isValid()) {
    return;
  } //if we can't find a valid or filled moment, we return.

  var split_afternoon = 12; //24hr time to split the afternoon
  var split_evening = 17; //24hr time to split the evening
  var currentHour = parseFloat(m.format("HH"));

  if (currentHour >= split_afternoon && currentHour <= split_evening) {
    g = "afternoon";
  } else if (currentHour >= split_evening) {
    g = "evening";
  } else {
    g = "morning";
  }

  return g;
};

export const combineItems = memoize(
  ({
    tasks = [],
    events = [],
    taskDateField = "scheduledDate",
    sortDirection = 1,
  }) => {
    let filteredEvents =
      events && events.length
        ? events.filter((e) => {
            return (
              e.myResponseStatus !== "declined" && e.startDateTime //&&
            );
          })
        : [];
    let filteredTasks = tasks && tasks.length ? tasks : [];
    let combined = [...filteredTasks, ...filteredEvents]; // must be in chronological order already
    // console.log(`resorting`);

    if (!combined.length) return [];
    // console.log(combined);

    const sort = combined.sort((left, right) => {
      const leftFieldName =
        left.__typename === "Task" ? taskDateField : "startDateTime";
      const rightFieldName =
        right.__typename === "Task" ? taskDateField : "startDateTime";

      if (left.__typename === "Task" && right.__typename === left.__typename) {
        if (taskDateField === "scheduledDate") {
          let dateDiff = 0;
          // if (left[taskDateField] && right[taskDateField]) {
          dateDiff =
            moment
              .utc(left[taskDateField])
              .diff(moment.utc(right[taskDateField])) * sortDirection;
          // } else {
          //   console.log(left);
          //   console.log(right);
          // }
          return dateDiff !== 0 ? dateDiff : left.localPos - right.localPos;
        }
        if (taskDateField === "completedAt") {
          return (
            moment
              .utc(left[taskDateField])
              .diff(moment.utc(right[taskDateField])) * sortDirection
          );
        }
      }

      const startDateDiff = moment
        .utc(left[leftFieldName])
        .diff(moment.utc(right[rightFieldName]));

      if (
        startDateDiff === 0 &&
        left.__typename === "Event" &&
        right.__typename === "Event"
      ) {
        return moment.utc(left.endDateTime).diff(moment.utc(right.endDateTime));
      } else return startDateDiff;
    });
    return sort;
  }
);

// export const getDayNameFromDate = ({ date, isDone }) => {
//   let mTaskDate = moment(date);
//   let formattedTaskDate = mTaskDate.format("YYYY-MM-DD");

//   let days = GetDaysFromCache().fullDays;
//   let daysDates = days
//     .filter((d) => moment(d.date, "YYYY-MM-DD").isValid())
//     .map((d) => d.date);
//   daysDates = daysDates.sort((a, b) => a.valueOf() - b.valueOf());

//   if (isDone) {
//     // done tasks
//     return "done";
//   } else if (!date) {
//     // inbox tasks
//     return "inbox";
//   } else if (daysDates.indexOf(formattedTaskDate) > -1) {
//     // tasks with a real date
//     return formattedTaskDate;
//   } else if (mTaskDate.isBefore(daysDates[0])) {
//     console.log("returning earlier");
//     return "earlier";
//   } else if (mTaskDate.isAfter(daysDates[daysDates.length - 1])) {
//     return "future";
//   }
// };

// export const getDayFromTask = ({ task, verifyExists }) => {
//   // let mTaskDate = moment(task.scheduledDate);
//   // let formattedTaskDate = mTaskDate.format("YYYY-MM-DD");

//   // let days = GetDaysFromCache().fullDays;
//   // let daysDates = days
//   //   .filter(d => moment(d.date, "YYYY-MM-DD").isValid())
//   //   .map(d => d.date);
//   // daysDates = daysDates.sort((a, b) => a.valueOf() - b.valueOf());

//   let day = GetOneDayFromCache(
//     getDayNameFromDate({
//       date: task.scheduledDate,
//       isDone: task.isDone,
//     })
//   );

//   if (
//     verifyExists &&
//     day.tasks &&
//     day.tasks.findIndex((t) => t._id === task._id) === -1
//   ) {
//     return false;
//   }

//   return day;
// };

// export const reapplyLocalPos = ({ tasks, scheduledDate }) => {
//   let dayTasks = [...tasks].filter(
//     (t) =>
//       moment(scheduledDate).isSame(t.scheduledDate, "day") ||
//       (!scheduledDate && !t.scheduledDate)
//   );
//   let newTasks = [...tasks];
//   newTasks = sortTasksByLocalPos(newTasks);
//   dayTasks.forEach((t, i) => {
//     // newTasks[i] = t;
//     const tIndex = newTasks.findIndex((nT) => nT._id === t._id);
//     newTasks[tIndex].localPos = i;
//   });
//   return newTasks;
// };

export const getWeekDays = () => {
  let weekDays = [];
  const SHOW_DAYS = 5;
  let day = 0;
  let usedDays = 0;
  while (usedDays < SHOW_DAYS) {
    let date = moment().add(day, "days");
    // console.log([6, 7].includes(date.isoWeekday()));
    if (![6, 7].includes(date.isoWeekday())) {
      usedDays++;
    }
    weekDays.push(date.format("YYYY-MM-DD"));
    day++;
  }
  // for (let i = 0; i < SHOW_DAYS; i++) {
  //   weekDays.push(moment().add(i, "days").format("YYYY-MM-DD"));
  // }
  return weekDays;
};

export const compileOptimisticTask = ({ taskId, updatedValues }) => {
  return {
    ...GetOneTaskFromCache(taskId),
    ...updatedValues,
    isOptimistic: true,
  };
};

export const wordsToMins = (wordCount) => {
  return Math.round(wordCount / 265);
};

export const reapplyLocalPos = ({ set, tasks, removeTaskId, simulate }) => {
  // const setId = ;
  const chunkTaskIds = tasks.map((t) => t._id);
  set = set || GetOneSetFromCache(moment().format("YYYY-MM-DD"));

  tasks.forEach(function (sT, i) {
    this[i].localPos = i;
  }, tasks);

  set.tasks = combineItems({
    tasks: [
      ...set.tasks.filter(
        (t) => !chunkTaskIds.includes(t._id) && t._id !== removeTaskId
      ),
      ...tasks,
    ],
  });

  return (!simulate && WriteSetIntoCache({ setId: set._id, set })) || set;
};

export const moveTaskHelper = ({
  sourceInfoObj,
  destInfoObj,
  destPos,
  origTask,
  moveMutation,
}) => {
  if (sourceInfoObj && destInfoObj && origTask) {
    const updateTask = {
      ...destInfoObj.applyFields,
      _id: origTask._id,
      localPos: destPos,
      __typename: "Task",
    };

    const simulatedSetTasks = InsertTaskIntoCache({
      task: updateTask,
      chunkInfo: destInfoObj,
      simulate: true,
    });

    const dayPos = simulatedSetTasks.find(
      (t) => t._id === updateTask._id
    ).localPos;

    moveMutation({
      variables: { ...updateTask, localPos: dayPos },
      optimisticResponse: {
        updateTask: {
          ...origTask,
          ...destInfoObj.applyFields,
          localPos: dayPos,
          isOptimistic: true,
        },
      },
      update: (cache, { data }) => {
        destInfoObj.chunkId !== sourceInfoObj.chunkId &&
          RemoveTaskFromCache({ task: origTask, chunkInfo: sourceInfoObj });
        InsertTaskIntoCache({
          task: { ...data.updateTask, localPos: destPos },
          chunkInfo: destInfoObj,
        });
      },
    });
  } else {
    console.log(
      `sourceInfoObj: ${!!sourceInfoObj}, destInfoObj: ${!!destInfoObj}, origTask: ${!!origTask}`
    );
  }
};
