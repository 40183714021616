import React from "react";
import { Form, Input, Button } from "antd";
import { SIGNUP_USER } from "../data/queries";
import styled from "styled-components";
import { UserModifier } from "../data/modifiers";
import { Helmet } from "react-helmet";
import {
  UserOutlined,
  MailOutlined,
  LockOutlined,
  LoginOutlined
} from "@ant-design/icons";
import { useMutation } from "@apollo/react-hooks";

const StyledSignupPage = styled.div`
  max-width: 300px;
  margin: 0 auto;
`;

// class SignupForm extends React.Component {
export default function SignupForm(props) {
  // const [confirmDirty, setConfirmDirty] = useState(false);
  const [
    signupUser,
    { loading: mutationLoading, error: mutationError }
  ] = useMutation(SIGNUP_USER);
  const handleSubmit = values => {
    // this.props.form.validateFields((err, values) => {
    // if (!err) {
    console.log("Received values of form: ", values);
    signupUser({
      variables: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password
      },
      update: (caches, { data }) => {
        // localStorage.setItem("token", data.loginUser.token);
        console.log("signing up");
        console.log(data.createUser);
        UserModifier.login(data.createUser, () => {
          // this.props.afterLogin();
          this.props.history.push("/");
        });
      }
    });
    // }
    // });
  };
  // const compareToFirstPassword = (rule, value, callback) => {
  //   const form = this.props.form;
  //   if (value && value !== form.getFieldValue("password")) {
  //     callback("The two passwords must match");
  //   } else {
  //     callback();
  //   }
  // };
  // const validateToNextPassword = (rule, value, callback) => {
  //   const form = this.props.form;
  //   if (value && confirmDirty) {
  //     form.validateFields(["confirm"], { force: true });
  //   }
  //   callback();
  // };
  // const handleConfirmBlur = e => {
  //   const value = e.target.value;
  //   setConfirmDirty(confirmDirty || !!value);
  //   console.log("setting dirty to: ", confirmDirty || !!value);
  // };
  return (
    <StyledSignupPage>
      <Helmet>
        <title>Dailydo - Sign up</title>
      </Helmet>
      <h1>Signup</h1>

      <Form className="login-form" onFinish={handleSubmit}>
        <Form.Item
          name="firstName"
          style={{ marginBottom: 0 }}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please input your first name!"
            }
          ]}
        >
          <Input
            autoFocus
            prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="First Name"
            type="text"
          />
        </Form.Item>
        <Form.Item
          name="lastName"
          style={{ marginBottom: 0 }}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please input your last name!"
            }
          ]}
        >
          <Input
            prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="Last Name"
            type="text"
          />
        </Form.Item>
        <Form.Item
          name="email"
          style={{ marginBottom: 0 }}
          hasFeedback
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!"
            },
            {
              required: true,
              message: "Please input your email!"
            }
          ]}
        >
          <Input
            prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="Email"
            type="email"
          />
        </Form.Item>
        <Form.Item
          name="password"
          style={{ marginBottom: 0 }}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please input your Password!"
            },
            {
              min: 5,
              message: "Passwords must be a minimum of 5 characters"
            }
            // {
            //   validator: this.validateToNextPassword
            // }
          ]}
        >
          <Input
            prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>

        <Form.Item
          name="confirm"
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password!"
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  "The two passwords that you entered do not match!"
                );
              }
            })
          ]}
        >
          <Input
            prefix={
              <LockOutlined type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
            }
            type="password"
            placeholder="Confirm Password"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={mutationLoading}
            icon={<LoginOutlined />}
          >
            Log in
          </Button>
        </Form.Item>
        {mutationError ? <p>Signup failed</p> : <p />}
      </Form>
    </StyledSignupPage>
  );
}

// SignupForm = Form.create({})(SignupForm);

// export default SignupForm;
