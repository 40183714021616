import React from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/react-hooks";
// import Spinner from "./Spinner";
import InlineField from "./InlineField";
import {
  ClockCircleOutlined,
  WarningOutlined,
  CheckCircleOutlined,
  AlignLeftOutlined
} from "@ant-design/icons";
import TaskDescription from "./TaskDescription";
import { GET_TASK } from "../data/queries";
import TaskCheckbox from "./TaskCheckbox";
// import { useQuery } from "@apollo/react-hooks";
import TaskDate from "./TaskDate";
import TaskEditableDate from "./TaskEditableDate";
import TaskDeleteButton from "./TaskDeleteButton";
import Skeleton from "react-loading-skeleton";
import TaskQuickRescheduleButton from "./TaskQuickRescheduleButton";
import moment from "moment-timezone";
import ModalRow from "./ModalRow";

const StyledTaskOverview = styled.div`
  padding: ${props => `${props.theme.grid.half}px`};
`;

const StyledActions = styled.div`
  display: flex;
  button + button {
    margin-left: auto;
  }
  margin-top: ${props => `${props.theme.grid.full}px`}};
`;

const StyledTitle = styled.div`
  color: inherit;
  font-family: "Markazi Text", serif;
  font-size: 22px;
  line-height: 22px;
  text-decoration: none;
  margin-bottom: 0;
`;

const StyledScheduledDateButtons = styled.div`
  margin-left: auto;
  display: inline-block;
`;

export default function TaskOverview(props) {
  const { onCancel, modalProps } = props;

  const { data, error, loading } = useQuery(GET_TASK, {
    variables: {
      _id: modalProps.taskId
    }
  });
  if (!modalProps) return `Error! No data provided`;
  if (loading)
    return (
      <StyledTaskOverview>
        <ModalRow
          iconStyle={{ marginRight: 5 }}
          icon={<TaskCheckbox isDisabled />}
          contentStyle={{ marginRight: 30 }}
        >
          <Skeleton height="30px" />
        </ModalRow>
        <ModalRow
          iconStyle={{ marginRight: 5 }}
          icon={<ClockCircleOutlined />}
          contentStyle={{ marginRight: 30 }}
        >
          <Skeleton height="32px" width="140px" />
        </ModalRow>
        <ModalRow
          iconStyle={{ marginRight: 5 }}
          icon={<WarningOutlined />}
          contentStyle={{ marginRight: 30 }}
        >
          <Skeleton height="32px" width="140px" />
        </ModalRow>
        <ModalRow
          iconStyle={{ marginRight: 5 }}
          icon={<AlignLeftOutlined />}
          contentStyle={{ marginRight: 30 }}
        >
          <Skeleton height="47px" />
        </ModalRow>
      </StyledTaskOverview>
    );
  if (error) return `Error! ${error.message}`;
  if (!data || !data.task) return `Error! No data returned`;

  console.log(data);
  const task = data.task; //GetOneTaskFromCache(modalProps.taskId);
  return (
    // const date = data && data.standup && data.standup.date;
    <StyledTaskOverview>
      <ModalRow
        icon={<TaskCheckbox task={task} isLoading={task.isOptimistic} />}
        contentStyle={{ marginRight: 30 }}
      >
        <InlineField
          _id={task._id}
          fieldName="name"
          WrapComponent={StyledTitle}
          isLoading={loading}
          submitOnBlur
          entityType="task"
        />
      </ModalRow>

      <ModalRow
        icon={<ClockCircleOutlined />}
        contentStyle={{ display: "flex", alignItems: "center" }}
      >
        <TaskEditableDate
          type="scheduled"
          date={task.scheduledDate}
          fieldName="scheduledDate"
          taskId={task._id}
          hideIcon
          highlightOnHover
          noBg
          isEditable
        />
        <StyledScheduledDateButtons>
          <TaskQuickRescheduleButton
            taskId={task._id}
            label="Today"
            date={moment()}
          />
          <TaskQuickRescheduleButton
            taskId={task._id}
            label="Tomorrow"
            date={moment().add(1, "days")}
          />
        </StyledScheduledDateButtons>
      </ModalRow>
      <ModalRow icon={<WarningOutlined />}>
        <TaskEditableDate
          type="due"
          date={task.dueDate}
          fieldName="dueDate"
          taskId={task._id}
          hideIcon
          highlightOnHover
          noBg
          isEditable
          truncTime
        />
      </ModalRow>
      {task.completedAt && (
        <ModalRow icon={<CheckCircleOutlined />}>
          <TaskDate
            type="completed"
            date={task.completedAt}
            hideIcon={true}
            showTime={true}
            highlightOnHover={true}
            noBg={true}
          />
        </ModalRow>
      )}
      <ModalRow icon={<AlignLeftOutlined />}>
        <TaskDescription description={task.desc} taskId={task._id} />
      </ModalRow>

      <StyledActions>
        <TaskDeleteButton task={task} onSuccess={onCancel} />
      </StyledActions>
    </StyledTaskOverview>
  );
}
