import React from "react";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";

const StyledRow = styled.div`
  display: flex;
  & + & {
    margin-top: ${(props) => `${props.theme.grid.full}px`};
  }
`;
const StyledRowIcon = styled.div`
  width: 30px;
  .ant-checkbox-wrapper {
    margin-top: 3px;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  ${(props) => props.center && `align-items: center; display: flex`}
`;
const StyledRowContent = styled.div`
  flex: 1;

  > button {
    margin-top: 3px;
  }

  button + button {
    margin-left: ${(props) => `${props.theme.grid.half}px`}};
  }
  span {
    span {
      display: block;
    }
    span + span {
      margin-top: ${(props) => `${props.theme.grid.quarter}px`}};
    }
  }
`;

export default function ModalRow(props) {
  const {
    icon,
    isLoading,
    children,
    contentStyle,
    iconStyle,
    skeletonHeight,
    skeletonWidth,
    skeletonCount,
    centerIcon,
  } = props;
  return (
    <StyledRow>
      {icon && (
        <StyledRowIcon center={centerIcon} style={iconStyle}>
          {icon}
        </StyledRowIcon>
      )}
      <StyledRowContent style={contentStyle}>
        {isLoading ? (
          <Skeleton
            height={skeletonHeight}
            width={skeletonWidth}
            count={skeletonCount}
          />
        ) : (
          children
        )}
      </StyledRowContent>
    </StyledRow>
  );
}
