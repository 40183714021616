import React, { useState } from "react";
import styled from "styled-components";
import { useMutation } from "@apollo/react-hooks";
import { Button } from "antd";
import { UPDATE_TASK } from "../data/queries";
import { compileOptimisticTask } from "../helpers";
import { Editor } from "@tinymce/tinymce-react";
import ReactHtmlParser from "react-html-parser";

const StyledTaskDesc = styled.div`
  color: ${(props) => `${props.theme.contextText}`};
  word-wrap: break-word;
  word-break: break-word;
  padding: ${(props) => props.theme.grid.half}px;
  margin-bottom: 0;
  width: 100%;
  min-height: ${(props) => props.theme.grid.double}px;
  transition: background-color 0.2s ease;

  border: 1px solid ${(props) => `${props.theme.colors.N30}`};
  p {
    margin-bottom: 0;
  }
  p + p {
    margin-top: 0.5em;
  }
  &:hover {
    background-color: ${(props) => `${props.theme.colors.N20}`};
    cursor: pointer;
  }
`;

export default function TaskDescription(props) {
  const [isEditMode, setIsEditMode] = useState(false);
  const { description, taskId } = props;
  const [editorState, setEditorState] = useState();
  const [updateTask] = useMutation(UPDATE_TASK);

  const handleEditorChange = (content, editor) => {
    setEditorState(content);
  };
  if (!isEditMode) {
    return (
      <StyledTaskDesc
        // dangerouslySetInnerHTML={{ __html: description }}
        onClick={() => {
          setIsEditMode(true);
        }}
      >
        {ReactHtmlParser(description)}
      </StyledTaskDesc>
    );
  }
  if (isEditMode)
    return (
      <React.Fragment>
        <Editor
          onEditorChange={handleEditorChange}
          initialValue={props.description}
          init={{
            auto_focus: true,
            content_style: "p, li { font-size: 14px; }",
            autosave: true,
            branding: false,
            menubar: false,
            statusbar: false,
            plugins: [
              "textpattern autoresize autolink lists link",
              "searchreplace code",
              "paste",
            ],
            toolbar:
              "formatselect | bold italic | bullist numlist outdent indent",
          }}
        />
        <Button
          onClick={() => {
            updateTask({
              variables: { _id: taskId, desc: editorState },
              optimisticResponse: {
                updateTask: compileOptimisticTask({
                  taskId,
                  updatedValues: { desc: editorState },
                }),
              },
            });
            setIsEditMode(false);
          }}
        >
          Save
        </Button>
        <Button
          onClick={() => {
            setIsEditMode(false);
          }}
        >
          Cancel
        </Button>
      </React.Fragment>
    );

  return <StyledTaskDesc />;
}
