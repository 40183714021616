import React from "react";
import styled from "styled-components";
import Textarea from "react-textarea-autosize";
import { Formik, Form, Field } from "formik";
import { GetOneTaskFromCache, UPDATE_TASK } from "../data/queries";
// import { GetOneLinkFromCache, UPDATE_LINK } from "../queries/LinkQueries";
import { useMutation } from "@apollo/react-hooks";

const StyledInlineField = styled.div`
  input,
  textarea {
    width: 100%;
    border: 1px solid transparent;
    resize: none;
    &:hover {
      background-color: ${(props) => props.theme.colors.N20};
    }
    &:focus {
      background-color: ${(props) => props.theme.colors.N0};
      border: 1px solid ${(props) => props.theme.colors.N30};
    }
  }
`;

export default function InlineField(props) {
  const {
    _id,
    // isLoading,
    fieldName,
    submitOnBlur,
    placeholder,
    WrapComponent = React.Fragment,
    entityType,
  } = props;

  const ENTITY_TYPE = {
    task: {
      cacheFunc: GetOneTaskFromCache,
      queryName: UPDATE_TASK,
      updateReturn: "updateTask",
    },
  };

  const cachedEntity = ENTITY_TYPE[entityType].cacheFunc(_id);
  const [updateEntity] = useMutation(ENTITY_TYPE[entityType].queryName);

  const onSubmit = function (values, actions) {
    if (values[fieldName] !== cachedEntity[fieldName]) {
      let updateEntityVariables = { _id };
      let optimisticEntity = { ...cachedEntity, isOptimistic: true };
      updateEntityVariables[fieldName] = values[fieldName];
      optimisticEntity[fieldName] = values[fieldName];

      const optimisticResponse = {};
      optimisticResponse[
        ENTITY_TYPE[entityType].updateReturn
      ] = optimisticEntity;

      updateEntity({
        variables: updateEntityVariables,
        optimisticResponse,
      });
    }
  };
  let initialValues = {};
  initialValues[fieldName] =
    (cachedEntity[fieldName] && cachedEntity[fieldName].toString()) || "";
  return (
    <WrapComponent>
      <StyledInlineField>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            onSubmit(values, actions);
            actions.setSubmitting(false);
          }}
        >
          {({ isSubmitting, handleSubmit, handleChange, values }) => (
            <Form>
              <Field
                component={Textarea}
                id={fieldName}
                name={fieldName}
                value={values[fieldName]}
                onChange={handleChange}
                onBlur={() => {
                  if (submitOnBlur && !isSubmitting) {
                    handleSubmit();
                  }
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13 && e.shiftKey === false) {
                    handleSubmit();
                    if (!isSubmitting) {
                      e.target.blur();
                    }
                    e.preventDefault();
                  }
                }}
                placeholder={placeholder || ""}
              />
            </Form>
          )}
        </Formik>
      </StyledInlineField>
    </WrapComponent>
  );
}
