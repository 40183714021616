import React from "react";
import styled from "styled-components";
import EventAttendee from "./EventAttendee";
const StyledEventAttendeeList = styled.ul`
  font-size: 12px;
  margin-bottom: 0;
  list-style-type: none;
  padding-left: 0;
`;

const StyledEmptyState = styled.div`
  font-size: 12px;
  line-height: 24px;
`;

export default function EventAttendeeList(props) {
  let attendees = props.attendees;
  if (attendees && attendees.length > 0) {
    attendees.sort((a1, a2) => {
      if (a1.organizer > a2.organizer) return -1;
      if (a1.organizer < a2.organizer) return 1;

      if (a1.responseStatus < a2.responseStatus) return -1;
      if (a1.responseStatus > a2.responseStatus) return 1;

      if (a1.email < a2.email) return -1;
      if (a1.email > a2.email) return 1;

      return 0;
    });

    let attendeeList = attendees.map((attendee, index) => {
      return (
        <EventAttendee
          key={index}
          attendee={attendee}
          linkify={props.linkify}
        />
      );
    });
    return (
      <React.Fragment>
        {attendeeList && (
          <StyledEventAttendeeList>{attendeeList}</StyledEventAttendeeList>
        )}
      </React.Fragment>
    );
  } else return <StyledEmptyState>{props.emptyState}</StyledEmptyState>;
}
