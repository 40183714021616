import React from "react";
import styled from "styled-components";
import LinkList from "./LinkList";
import LinkCreate from "./LinkCreate";
import PageHeader from "./PageHeader";
import media from "../styles/media-queries";
import LinkOverview from "./LinkOverview";
import Nav from "./Nav";
import { LinkOutlined } from "@ant-design/icons";

const StyledLinksPage = styled.div`
  margin: 0 ${(props) => props.theme.grid.full}px;
`;

const StyledList = styled.div`
  flex: 1;
  max-width: 350px;
  overflow: auto;
  border-radius: ${(props) => props.theme.borderRadius};
  padding: ${(props) => props.theme.grid.full}px;
  background-color: ${(props) => props.theme.colors.N20};
  ${media.tablet`
    max-width: 100%;
`}
`;
// ${props => props.isLinkSelected && `display: none;`}
//
//
const StyledLinkContent = styled.div`
  flex: 1;
  overflow: auto;
  background-color: ${(props) => props.theme.colors.N0};
  margin-left: ${(props) => props.theme.grid.full}px;
  ${media.tablet`
    ${(props) => !props.isLinkSelected && `display: none;`}
    ${(props) =>
      props.isLinkSelected &&
      `
      z-index: 1;
      padding: ${props.theme.grid.full}px;
      position: fixed;
      top:0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
    `}
  `}
`;

const StyledLinksPageContent = styled.div`
  height: calc(100vh - 131px);
  padding: 0 0 20px 0;
  display: flex;
  max-width: 900px;
  margin: 0 auto;

  ${media.tablet`
    display: block;
    height:auto;
    padding: 0;
`}

  > div {
    flex: 1;
  }
`;

const StyledListHeading = styled.h3`
  ul + & {
    margin-top: ${(props) => props.theme.grid.full}px;
  }
`;

const StyledHeading = styled.div`
  text-align: center;
  margin-top: 100px;
  color: ${(props) => props.theme.colors.N80};
  .anticon {
    font-size: 24px;
    display: block;
  }
  h2 {
    display: inline-block;
    color: ${(props) => props.theme.colors.R500};
  }
`;

export default function LinksPage(props) {
  const {
    match: {
      params: { linkId },
    },
  } = props;
  return (
    <StyledLinksPage>
      <PageHeader title={`Hey, ${localStorage.getItem("firstName")} 👋`}>
        <Nav />
      </PageHeader>
      <StyledLinksPageContent>
        <StyledList isLinkSelected={!!linkId}>
          <LinkCreate />
          <StyledListHeading>Unread</StyledListHeading>
          <LinkList status={"unread"} />
          <StyledListHeading>Read</StyledListHeading>
          <LinkList status={"read"} limit={5} />
        </StyledList>
        <StyledLinkContent isLinkSelected={!!linkId}>
          {linkId && <LinkOverview modalProps={{ _id: linkId }} />}
          {!linkId && (
            <div>
              <StyledHeading>
                <LinkOutlined />
                Select a link
              </StyledHeading>
            </div>
          )}
        </StyledLinkContent>
      </StyledLinksPageContent>
    </StyledLinksPage>
  );
}
