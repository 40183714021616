import React from "react";
import styled from "styled-components";
import Event from "./Event";

const StyledAllDayEvents = styled.div`
  margin: ${(props) => props.theme.grid.quarter}px 0
    ${(props) => props.theme.grid.tiny}px 0;
`;

export default function AllDayEvents(props) {
  const { allDayEvents } = props;

  if (allDayEvents && allDayEvents.length) {
    return (
      <StyledAllDayEvents>
        {allDayEvents.map((e, i) => {
          return <Event key={e._id} {...e} isAllDay={true} />;
        })}
      </StyledAllDayEvents>
    );
  } else return null;
}
