import React from "react";
import styled from "styled-components";
import { RESPOND_EVENT } from "../data/queries";
import { useMutation } from "@apollo/react-hooks";

const StyledResponseButton = styled("div")`
  border-radius: ${props => `${props.theme.borderRadius}`};
  border: 1px solid transparent;
  display: inline-block;
  padding: ${props => `${props.theme.grid.tiny}px ${props.theme.grid.half}px`};

  cursor: pointer;

  & + & {
    margin-left: ${props => `${props.theme.grid.half}px`};
  }

  ${props => {
    if (props.responseStatus === "accepted" && props.selected) {
      return `
        background-color: ${props.theme.colors.G400};
        color: ${props.theme.colors.N0};
        cursor: default;
      `;
    } else if (props.responseStatus === "tentative" && props.selected) {
      return `background-color: ${props.theme.colors.N40};`;
    } else if (props.responseStatus === "declined" && props.selected) {
      return `
        background-color: ${props.theme.colors.R400}
        color: ${props.theme.colors.N0};
        cursor: default;
      `;
    } else if (props.responseStatus === "declined" && !props.selected) {
      return ``;
    }
  }}

  ${props => {
    if (!props.selected) {
      return `         
        &:hover {
          color: ${props.theme.colors.N400};
          background-color: ${props.theme.colors.N30};
        }
      `;
    }
  }}
`;

export default function EventRespondButtons(props) {
  const [respondEvent] = useMutation(RESPOND_EVENT);

  const responseStatuses = [
    { label: "Yes", value: "accepted" },
    { label: "No", value: "declined" },
    { label: "Maybe", value: "tentative" }
  ];
  return (
    <React.Fragment>
      {responseStatuses.map(responseStatus => (
        <StyledResponseButton
          key={responseStatus.value}
          responseStatus={responseStatus.value}
          selected={props.event.myResponseStatus === responseStatus.value}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            const event = JSON.parse(JSON.stringify(props.event)); //Object.assign({}, this.props);

            if (props.event.myResponseStatus !== responseStatus.value) {
              const selfAttendeeIndex =
                event.attendees &&
                event.attendees.findIndex(attendee => {
                  return attendee.self;
                });

              if (selfAttendeeIndex >= 0) {
                event.attendees[selfAttendeeIndex].responseStatus =
                  responseStatus.value;

                event.myResponseStatus = responseStatus.value;

                respondEvent({
                  variables: {
                    _id: props.event._id,
                    myResponseStatus: responseStatus.value
                  },
                  optimisticResponse: {
                    __typename: "Mutation",
                    respondToEvent: {
                      _id: event._id,
                      myResponseStatus: event.myResponseStatus,
                      attendees: event.attendees,
                      isOptimistic: true,
                      __typename: "Event"
                    }
                  }
                });
              }
            }
          }}
        >
          {responseStatus.label}
        </StyledResponseButton>
      ))}
    </React.Fragment>
  );
}
