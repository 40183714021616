import React from "react";
import styled from "styled-components";

export const StyledContextItem = styled.div`
  display: inline-block;
  transition: all 0.2s ease;
  color: ${(props) =>
    props.color ||
    (props.isActive
      ? props.theme.contextTextSubtle
      : props.theme.taskBorderHover)};
  background-color: ${(props) =>
    props.contextBg ? props.theme.contextBg : "transparent"};
  border-radius: ${(props) => props.theme.borderRadius};
  padding: 0 ${(props) => props.theme.grid.quarter}px;
  font-size: 12px;

  span + span {
    margin-left: ${(props) => props.theme.grid.quarter}px;
  }
  ${(props) =>
    props.highlightOnHover &&
    `&:hover {background-color: ${props.theme.contextBg}}`}

  ${(props) => typeof props.onClick === "function" && `cursor:pointer;`}
  ${(props) => props.fullWidth && `width:100%;`}

  & + & {
    margin-left: 4px;
  }
`;

export default function ContextItem(props) {
  return (
    <StyledContextItem
      color={props.color}
      bgColor={props.bgColor}
      highlightOnHover={props.highlightOnHover}
      onClick={props.onClick}
      fullWidth={props.fullWidth}
      isActive={props.isActive}
    >
      {props.icon}
      <span>{props.children}</span>
    </StyledContextItem>
  );
}
