import React, { useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import media from "../styles/media-queries";
import { lightTheme } from "../styles/themes";
import ConnectionError from "./ConnnectionError";

const StyledElement = styled.div`
  & + & {
    margin-top: 20px;
  }
`;
export const StyledDayGroup = styled("div")`
  background-color: ${(props) => props.theme.dayBg};
  border-radius: ${(props) => props.theme.borderRadius};
  width: 380px;
  ${media.tablet`
    border-radius: 0;
  `};

  ${(props) =>
    `border: 2px solid ${
      props.isToday ? props.theme.dayGroupBorderColor : props.theme.contextBg
    };
  `};

  h2 {
    margin-bottom: ${(props) => props.theme.grid.half}px;

    & + div {
      margin-top: ${(props) => props.theme.grid.half}px;
    }
  }
`;

const StyledDayGroupInner = styled.div`
  transition: all 0.2s ease;

  padding: ${(props) => props.theme.grid.half}px;
`;

// ${(props) =>
//   props.isToday &&
//   `background-color: ${props.theme.dayGroupBorderColor};
//   color: ${lightTheme.colors.N0}
//   `};

const DayGroupTitle = styled.h2`
  padding: ${(props) => props.theme.grid.half}px
  color: ${(props) =>
    props.theme.dayGroupBorderColor || props.theme.eventTextColor};

    ${(props) => props.isToday && `text-decoration:underline;`}
  cursor: pointer;

`;

const StyledCollapsedTitle = styled.h2`
  writing-mode: vertical-rl;
  padding: ${(props) => props.theme.grid.half}px
    ${(props) => props.theme.grid.quarter}px;
  border-radius: ${(props) => props.theme.borderRadius};
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.contextBg};
  }
`;

// background-color: ${props.theme.dayGroupBorderColor};
// color: ${lightTheme.colors.N0}
// `};

const DayTheme = {
  contextBg: lightTheme.colors.N30,
  contextText: lightTheme.colors.N700,
  contextTextSubtle: lightTheme.colors.N400,
  dayBg: lightTheme.colors.N20,
  dayText: lightTheme.colors.N800,
  dayTextHover: lightTheme.colors.N800,
  eventBg: lightTheme.colors.N0,
  attendeeBg: lightTheme.colors.N20,
  attendeeBgHover: lightTheme.colors.N30,
  eventTextColor: lightTheme.colors.N800,
  eventExpandTextColor: lightTheme.colors.N80,
  eventAcceptedColor: lightTheme.colors.G500,
  eventDeclinedColor: lightTheme.colors.R500,
  taskBorderHover: lightTheme.colors.N50,
  taskBgHover: lightTheme.colors.N0,
  topMargin: lightTheme.grid.double,
  ...lightTheme,
};

const TodayTheme = {
  ...DayTheme,
  dayGroupBorderColor: lightTheme.colors.N900,
  topMargin: lightTheme.grid.full,
  ...lightTheme,
  // contextBg: lightTheme.colors.N800,
  // contextText: lightTheme.colors.N40,
  // contextTextSubtle: lightTheme.colors.N80,
  // dayBg: lightTheme.colors.N900,
  // dayText: lightTheme.colors.N20,
  // dayTextHover: lightTheme.colors.N20,
  // eventBg: lightTheme.colors.N200,
  // attendeeBg: lightTheme.colors.N700,
  // attendeeBgHover: lightTheme.colors.N600,
  // eventTextColor: lightTheme.colors.N10,
  // eventExpandTextColor: lightTheme.colors.N60,
  // eventAcceptedColor: lightTheme.colors.G500,
  // eventDeclinedColor: lightTheme.colors.R500,
  // taskBorderHover: lightTheme.colors.N600,
  // taskBgHover: lightTheme.colors.N700,
  // topMargin: lightTheme.grid.full,
  // ...lightTheme
};

export default function DayGroup(props) {
  const { isCollapsed } = props;
  const [isCollapsedState, setIsCollapsedState] = useState(
    isCollapsed || false
  );

  const TitleTag = isCollapsedState ? StyledCollapsedTitle : DayGroupTitle;

  return (
    <StyledElement>
      <ThemeProvider
        theme={() => {
          return props.isToday ? TodayTheme : DayTheme;
        }}
      >
        {/* {props.children} */}
        <TitleTag
          isToday={props.isToday}
          onClick={() => setIsCollapsedState(!isCollapsedState)}
        >
          {props.title}
        </TitleTag>
        {!isCollapsedState && (
          <StyledDayGroup isToday={props.isToday}>
            {props.error ? (
              <ConnectionError error={props.error} />
            ) : (
              <StyledDayGroupInner>{props.children}</StyledDayGroupInner>
            )}
          </StyledDayGroup>
        )}
      </ThemeProvider>
    </StyledElement>
  );
}
