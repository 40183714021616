import React from "react";
import styled from "styled-components";
import Spinner from "./Spinner";
import { useQuery } from "@apollo/react-hooks";
import { GET_HABITS_OVERVIEW, UPDATE_HABIT } from "../data/queries";
import { Switch } from "antd";
// import { CheckSquareFilled, CloseSquareFilled } from "@ant-design/icons";
import moment from "moment-timezone";
import { useMutation } from "@apollo/react-hooks";

const StyledHabitsOverview = styled.div`
  padding: ${(props) => `${props.theme.grid.half}px`};
`;
const StyledHabit = styled.div`
  margin: ${(props) => `${props.theme.grid.half}px`} 0;
  padding: ${(props) => `${props.theme.grid.half}px`};
  border-radius: ${(props) => props.theme.borderRadius};
  border: 1px solid ${(props) => props.theme.colors.N30};
  background-color: ${(props) => props.theme.colors.N10};
`;
const StyledHabitHeader = styled.div`
  display: flex;
  h3 {
    margin-left: ${(props) => `${props.theme.grid.half}px`};
  }
`;

// const StyledSwitch = styled.div`
//   margin-right: 0;
//   margin-left: auto;
// `;

// const StyledActions = styled.div`
//   button + button {
//     margin-left: ${(props) => `${props.theme.grid.quarter}px`}};
//   }
// `;

const StyledTable = styled.table`
  width:100%;
  margin: ${(props) => `${props.theme.grid.full}px 0`}
  text-align: center;
  table-layout: fixed;
`;

export default function HabitsOverview(props) {
  // const { onCancel } = props;
  return (
    <StyledHabitsOverview>
      <h2>Habits</h2>
      <HabitsOverviewContent />
      {/* <StyledActions>
        <Button onClick={onCancel}>Close</Button>
      </StyledActions> */}
    </StyledHabitsOverview>
  );
}

export function HabitsOverviewContent(props) {
  const { loading, error, data } = useQuery(GET_HABITS_OVERVIEW, {
    fetchPolicy: "network-only",
  });
  if (loading) return <Spinner />;
  if (error) return `Error! ${error.message}`;
  if (!data) return `Error! No data returned`;

  const habits = data.habits;

  return (
    <React.Fragment>
      {habits.map((habit, index) => (
        <Habit habit={habit} key={index} />
      ))}
    </React.Fragment>
  );
}

export function Habit(props) {
  const { habit } = props;
  const ISOWEEKDAYS = moment.weekdays().map((wd, i) => {
    return { fullDay: wd, shortDay: moment.weekdaysShort()[i] };
  });
  const [updateHabit] = useMutation(UPDATE_HABIT);
  return (
    <StyledHabit>
      <StyledHabitHeader>
        <Switch
          checked={habit.isEnabled}
          size="small"
          onClick={() => {
            updateHabit({
              variables: {
                _id: habit._id,
                isEnabled: !habit.isEnabled,
              },
              optimisticResponse: {
                updateHabit: {
                  ...habit,
                  isEnabled: !habit.isEnabled,
                  // isOptimistic: true,
                },
              },
            });
            console.log(habit._id);
          }}
        />
        <h3>{habit.name}</h3>
      </StyledHabitHeader>
      <StyledTable>
        <thead>
          <tr>
            {ISOWEEKDAYS.map((d, index) => (
              <th key={index}>{d.shortDay}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {ISOWEEKDAYS.map((d, index) => (
              <td key={index}>
                <input
                  type="checkbox"
                  checked={habit.days.includes(d.fullDay)}
                  onChange={() => {}}
                />
              </td>
            ))}
          </tr>
        </tbody>
      </StyledTable>

      <div>Longest streak: {habit.highStreak}</div>
      <div>Current streak: {habit.currentStreak}</div>
    </StyledHabit>
  );
}
