import React from "react";
import styled from "styled-components";
import Textarea from "react-textarea-autosize";
import { Formik, Form, Field } from "formik";
import { GetOneLinkFromCache, UPDATE_LINK } from "../queries/LinkQueries";
import { useMutation } from "@apollo/react-hooks";

const StyledLinkInlineField = styled.div`
  input,
  textarea {
    width: 100%;
    border: 1px solid transparent;
    resize: none;
    &:hover {
      background-color: ${(props) => props.theme.colors.N20};
    }
    &:focus {
      background-color: ${(props) => props.theme.colors.N0};
      border: 1px solid ${(props) => props.theme.colors.N30};
    }
  }
`;

export default function LinkInlineField(props) {
  const {
    _id,
    // isLoading,
    fieldName,
    submitOnBlur,
    placeholder,
    WrapComponent = React.Fragment,
  } = props;

  const cachedLink = GetOneLinkFromCache(_id);
  const [updateLink] = useMutation(UPDATE_LINK);

  const onSubmit = function (values, actions) {
    if (values[fieldName] !== cachedLink[fieldName]) {
      let updateLinkVariables = { _id };
      let optimisticLink = { ...cachedLink, isOptimistic: true };
      updateLinkVariables[fieldName] = values[fieldName];
      optimisticLink[fieldName] = values[fieldName];

      updateLink({
        variables: updateLinkVariables,
        optimisticResponse: {
          updateLink: optimisticLink,
        },
      });
    }
    // fieldRef.current.blur();
  };
  let initialValues = {};
  initialValues[fieldName] =
    (cachedLink[fieldName] && cachedLink[fieldName].toString()) || "";
  return (
    <WrapComponent>
      <StyledLinkInlineField>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            onSubmit(values, actions);
            actions.setSubmitting(false);
          }}
        >
          {({ isSubmitting, handleSubmit, handleChange, values }) => (
            <Form>
              <Field
                component={Textarea}
                id={fieldName}
                name={fieldName}
                value={values[fieldName]}
                onChange={handleChange}
                onBlur={() => {
                  if (submitOnBlur && !isSubmitting) {
                    handleSubmit();
                  }
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13 && e.shiftKey === false) {
                    handleSubmit();
                    if (!isSubmitting) {
                      e.target.blur();
                    }
                    e.preventDefault();
                  }
                }}
                placeholder={placeholder || ""}
              />
            </Form>
          )}
        </Formik>
      </StyledLinkInlineField>
    </WrapComponent>
  );
}
