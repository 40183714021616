import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import media from "../styles/media-queries";
// import { CheckCircleOutlined, ReadOutlined } from "@ant-design/icons";

const StyledHeader = styled.header`
  padding: 10px;
  position: fixed;
  background-color: white;
  width: 100%;
  z-index: 99;
  background-color: ${(props) => props.theme.colors.N10};

  h1 {
    margin-bottom: 0;
  }
`;
const StyledHeaderTitle = styled.div`
  display: flex;
  align-items: center;
`;

const StyledNavLinks = styled.div`
  display: flex;
  margin-left: auto;
  ${media.tablet`
    flex-direction:column;
  `};
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  transition: all 0.2s;
  color: inherit;
  padding: ${(props) => props.theme.grid.tiny}px
    ${(props) => props.theme.grid.quarter}px;
  span {
    margin-right: ${(props) => props.theme.grid.quarter}px;
  }
  & + & {
    margin-left: ${(props) => props.theme.grid.half}px;
  }
  ${media.tablet`
    & + & {
      margin-left: 0;
    }
  `};

  &.active {
    background-color: ${(props) => props.theme.colors.N20};
  }
  &:hover {
    color: ${(props) => props.theme.colors.G400};
  }
`;

export default function PageHeader(props) {
  return (
    <StyledHeader>
      <StyledHeaderTitle>
        {/* <h1>{props.title}</h1> */}
        {props.children}
        <StyledNavLinks>
          <StyledNavLink to="/" exact>
            {/* <CheckCircleOutlined /> */}
            All Tasks
          </StyledNavLink>
          <StyledNavLink to="/done" exact>
            {/* <CheckCircleOutlined /> */}
            Done Tasks
          </StyledNavLink>
          <StyledNavLink to="/read">
            {/* <ReadOutlined /> */}
            Reading List
          </StyledNavLink>
        </StyledNavLinks>
      </StyledHeaderTitle>
    </StyledHeader>
  );
}
