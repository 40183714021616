import { createStore, createHook } from "react-sweet-state";
import client from "../client";

const Store = createStore({
  initialState: { isLoggedIn: !!localStorage.getItem("token") },
  actions: {
    afterLogin: () => ({ setState }) => {
      client.resetStore().then(() => {
        setState({ isLoggedIn: true });
      });
    },
    afterLogout: () => ({ setState }) => {
      setState({ isLoggedIn: false });
      client.resetStore().then(() => {});
    }
  }
});

export const useUserStore = createHook(Store);
