import { createStore, createHook } from "react-sweet-state";

const actions = {
  setTaskId: (taskId) => ({ setState }) => {
    // console.log(`setting taskId to ${taskId}`);
    setState({ taskId });
  },
  setTaskDetails: ({ _id, isDone, scheduledDate }) => ({ setState }) => {
    // console.log(`setting taskId to ${taskId}`);
    setState({ _id, isDone, scheduledDate });
  },
  clear: () => ({ dispatch }) => {
    dispatch(
      actions.setTaskDetails({ _id: null, isDone: null, scheduledDate: null })
    );
  },
};

const Store = createStore({
  initialState: { _id: null, isDone: null, scheduledDate: null },
  actions: actions,
});

export const useHoveredTaskStore = createHook(Store);
export const useHoveredTaskActions = createHook(Store, { selector: null });
