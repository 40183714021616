import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { Button } from "antd";
import { Formik, Form, Field } from "formik";
import { CREATE_LINK, InsertLinkIntoCache } from "../queries/LinkQueries";
import { guidGenerator } from "../helpers";
import * as Yup from "yup";
import styled from "styled-components";

const StyledFormContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: ${(props) => props.theme.grid.full}px;
`;
const StyledFieldContainer = styled.div`
  width: 100%;
  margin-right: ${(props) => props.theme.grid.half}px;
`;
const StyledField = styled(Field)`
  width: 100%;
  padding: ${(props) => props.theme.grid.quarter}px;
`;

export default function LinkCreate(props) {
  const [createLink] = useMutation(CREATE_LINK, {
    update(cache, { data: { createLink } }) {
      InsertLinkIntoCache(createLink);
    },
  });
  const LinkSchema = Yup.object().shape({
    url: Yup.string().url().required("Required"),
  });

  return (
    <Formik
      initialValues={{ url: "" }}
      validationSchema={LinkSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        createLink({
          variables: { url: values.url },
          optimisticResponse: {
            createLink: {
              _id: guidGenerator(),
              author: "",
              url: values.url,
              createdAt: "",
              domain: "",
              wordCount: 0,
              excerpt: null,
              notes: null,
              rating: null,
              numQuotes: 0,
              isOptimistic: true,
              readAt: null,
              status: "unread",
              title: "",
              userId: "",
              __typename: "Link",
            },
          },
        });
        setSubmitting(false);
        resetForm({ url: "" });
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <StyledFormContainer>
            <StyledFieldContainer>
              <StyledField
                name="url"
                placeholder="https://www.example.com/article.html"
                autoComplete="off"
              />
            </StyledFieldContainer>
            <Button
              type="primary"
              htmlType="submit"
              size="small"
              disabled={isSubmitting}
            >
              Save
            </Button>
          </StyledFormContainer>
        </Form>
      )}
    </Formik>
  );
}
