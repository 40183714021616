import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import styled from "styled-components";
import { useModalActions } from "../stores/ModalStore";
import EventResponseIcon from "./EventResponseIcon";
import ContextItem from "./ContextItem";
import {
  VideoCameraOutlined,
  WarningOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { lightTheme } from "../styles/themes";
// background: repeating-linear-gradient(   45deg,   ${props.theme.contextBg},   ${props.theme.contextBg} 2px,   transparent 2px,   transparent 4px );

// background-color: ${props => `${props.theme.eventBg}`};

const PAST_STATE = "past";
const CURRENT_STATE = "current";
const FUTURE_STATE = "future";

// margin: ${(props) => `${props.theme.grid.tiny}px 0`};

// white-space: nowrap;

export const StyledEventItem = styled("div")`
  background-color: ${(props) =>
    props.state === CURRENT_STATE
      ? props.theme.colors.B50
      : props.theme.contextBg};
  color: ${(props) =>
    props.state === PAST_STATE
      ? props.theme.colors.N100
      : props.theme.eventTextColor};
  text-decoration: ${(props) =>
    props.state === PAST_STATE ? "line-through" : "none"};
  line-height: 20px;
  padding: ${(props) => `${props.theme.grid.half}px`};
  transition: height 1s, background-color 0.2s;
  cursor: pointer;
  border-radius: ${(props) => props.theme.borderRadius};
  border: 2px solid
    ${(props) =>
      props.state === CURRENT_STATE ? props.theme.colors.B200 : "transparent"};
  text-decoration: ${(props) =>
    props.myResponseStatus === "declined" ? "line-through" : ""};

  ${(props) =>
    props.myResponseStatus === "needsAction" &&
    `
      background-color: transparent !important;
      border: 1px solid ${props.theme.taskBorderHover};
    `};
  ${(props) =>
    props.myResponseStatus === "tentative" &&
    `
    background: repeating-linear-gradient(   45deg,   ${props.theme.colors.N30},   ${props.theme.colors.N30} 2px,   transparent 2px,   transparent 4px );
    `};

  a {
    color: inherit;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  > a {
    margin-left: auto;
    color: rgba(128, 128, 128, 0.7);
  }

  &:hover {
    background-color: ${(props) =>
      props.state === CURRENT_STATE
        ? props.theme.colors.N20
        : props.theme.colors.N40} !important;
  }
`;
// border: 1px solid ${props => props.theme.contextBg};

// margin-bottom: ${props => `${props.theme.grid.tiny}px`};
const StyledEventContext = styled("div")`
  display: flex;
  span {
    margin-top: 2px;
    margin-left: auto;
    margin-right: 0;
  }
`;

const StyledEventItemTime = styled("div")`
  flex-shrink: 0;
  font-weight: bold;
  margin-right: ${(props) => props.theme.grid.half}px;
  font-size: 12px;
  border-right: 1px solid ${(props) => props.theme.colors.N50};
  padding-right: ${(props) => props.theme.grid.half}px;
`;

// const StyledStatusIcon = styled.div`
//   margin-right: ${(props) => props.theme.grid.quarter}px;
// `;

const StyledEventDetails = styled.div`
  overflow: hidden;
`;
const StyledEventContextItems = styled.div`
  padding: ${(props) => `${props.theme.grid.quarter}px 0 0 0`};
`;

const niceResponseStatus = (myResponseStatus) => {
  switch (myResponseStatus) {
    case "accepted":
      return "Accepted";
    case "tentative":
      return "Tentative";
    case "declined":
      return "Declined";
    case "needsAction":
    default:
      return "No response";
  }
};

const getEventState = (startDateTime, endDateTime) => {
  let state = FUTURE_STATE;
  if (moment().isAfter(startDateTime)) {
    if (moment().isBefore(endDateTime)) {
      state = CURRENT_STATE;
    } else state = PAST_STATE;
  }
  return state;
};

export default function Event(props) {
  const [, modalStoreActions] = useModalActions();
  const [eventState, setEventState] = useState(
    getEventState(props.startDateTime, props.endDateTime)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setEventState(getEventState(props.startDateTime, props.endDateTime));
    }, 60 * 1000); // 60 seconds
    return () => clearInterval(interval);
  }, [setEventState, props.startDateTime, props.endDateTime]);

  const defaultHeight = 66;
  let eventHeight = props.duration.hrs * defaultHeight * 2;
  eventHeight = props.isAllDay
    ? defaultHeight
    : Math.min(Math.max(eventHeight, defaultHeight), 640);

  return (
    <StyledEventItem
      myResponseStatus={props.myResponseStatus}
      onClick={() => {
        modalStoreActions.open("eventOverview", { eventId: props._id });
      }}
      style={{ minHeight: eventHeight }}
      state={eventState}
    >
      <StyledEventContext>
        <StyledEventItemTime>
          {!props.isAllDay ? (
            <React.Fragment>
              {moment(props.startDateTime).format("HH:mm")}
              <br />
              {moment(props.endDateTime).format("HH:mm")}
            </React.Fragment>
          ) : (
            "All Day"
          )}
        </StyledEventItemTime>
        <StyledEventDetails>
          <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
            {props.warnings && props.warnings.conflict && (
              <WarningOutlined
                style={{ marginRight: 5, color: lightTheme.colors.R500 }}
              />
            )}
            {props.summary}
          </div>
          <StyledEventContextItems>
            <ContextItem
              icon={<EventResponseIcon status={props.myResponseStatus} />}
              isActive={true}
            >
              {niceResponseStatus(props.myResponseStatus)}
            </ContextItem>
            {props.attendeeStats && props.attendeeStats.total && (
              <ContextItem
                icon={<UserOutlined />}
                isActive={!!props.attendees && props.attendees.length}
              >
                {props.attendeeStats.accepted} / {props.attendeeStats.total}
              </ContextItem>
            )}
            <ContextItem
              icon={<VideoCameraOutlined />}
              isActive={!!props.zoomUrl}
              highlightOnHover={!!props.zoomUrl}
              onClick={(e) => {
                e.stopPropagation();
                if (props.zoomUrl) {
                  window.open(props.zoomUrl, "_blank");
                }
              }}
            >
              Zoom
            </ContextItem>
            {/* {props.warnings && props.warnings.conflict && (
              <ContextItem
                icon={<WarningOutlined />}
                isActive
                color={lightTheme.colors.R500}
              >
                Conflict
              </ContextItem>
            )} */}
          </StyledEventContextItems>
        </StyledEventDetails>
      </StyledEventContext>
    </StyledEventItem>
  );
}
