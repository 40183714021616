import React from "react";
import styled from "styled-components";
import { GET_STANDUP } from "../data/queries";
import { Button } from "antd";
import moment from "moment-timezone";
import Day from "./Day";
import DayGroup from "./DayGroup";
import { DragDropContext } from "react-beautiful-dnd";
import { useQuery } from "@apollo/react-hooks";
import Spinner from "./Spinner";

const StyledStandupOverview = styled.div`
  padding: ${props => `${props.theme.grid.half}px`};
`;
const StyledActions = styled.div`
  button + button {
    margin-left: ${props => `${props.theme.grid.quarter}px`}};
  }
  margin-top: ${props => `${props.theme.grid.full}px`}};
`;

const getPreviousWorkday = () => {
  let workday = moment();
  let day = workday.day();
  let diff = 1; // returns yesterday
  if (day === 0 || day === 1) {
    // is Sunday or Monday
    diff = day + 2; // returns Friday
  }
  return workday.subtract(diff, "days");
};

export default function StandupOverview(props) {
  const { onCancel } = props;
  const previousWorkDay = getPreviousWorkday().format("YYYY-MM-DD");

  const { data, error, loading } = useQuery(GET_STANDUP, {
    variables: {
      date: previousWorkDay,
      timezone: moment.tz.guess()
    }
  });

  if (loading) return <Spinner />;
  if (error) return `Error! ${error.message}`;
  if (!data) return `Error! No data returned`;
  const standup = data.standup;

  return (
    // const date = data && data.standup && data.standup.date;
    <StyledStandupOverview>
      <DayGroup title="Standup" isInbox={true}>
        <DragDropContext
          onDragEnd={result => {
            console.log("blah");
          }}
        >
          <Day
            key={previousWorkDay}
            id={previousWorkDay}
            date={previousWorkDay}
            tasks={standup && standup.tasks}
            events={standup && standup.events}
            isDropDisabled={true}
            isLoading={loading}
            taskDateField="completedAt"
            dailyHabits={standup && standup.dailyHabits}
            disableInlineCreate={true}
          />
        </DragDropContext>
      </DayGroup>
      <StyledActions>
        <Button onClick={onCancel}>Close</Button>
      </StyledActions>
    </StyledStandupOverview>
  );
}
