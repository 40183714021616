import React from "react";
import { TaskModifier } from "../data/modifiers";
import { Checkbox } from "antd";
import styled from "styled-components";
import { useMutation } from "@apollo/react-hooks";
import { LoadingOutlined } from "@ant-design/icons";
// import { softRemoveTask } from "../data/queries";

const StyledSpinner = styled.div`
  margin-left: ${(props) => props.theme.grid.quarter}px;
  margin-right: ${(props) => props.theme.grid.half}px;
  min-width: 16px;
  min-height: 16px;
`;

export default function TaskCheckbox(props) {
  const { isLoading, task, isDisabled /*, useSoft*/ } = props;
  const { mutation } = TaskModifier.completeTask({ task: task || {} });
  const [updateTask] = useMutation(mutation);

  if (isLoading)
    return (
      <StyledSpinner>
        <LoadingOutlined style={{ color: "grey" }} />
      </StyledSpinner>
    );

  return (
    <Checkbox
      checked={task && task.isDone ? true : false}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onChange={() => {
        // useSoft && softRemoveTask({ taskId: task._id });
        // setTimeout(
        //   () => {
        const {
          update,
          optimisticResponse,
          variables,
        } = TaskModifier.completeTask({ task: task || {} });
        updateTask({ update, optimisticResponse, variables });
        // },
        // useSoft ? 300 : 0
        // );
      }}
      style={{ marginLeft: 5, marginRight: 10 }}
      disabled={isDisabled}
    />
  );
}
