import React from "react";
import styled from "styled-components";
import DailyHabit from "./DailyHabit";

const StyledDailyHabitsList = styled.div`
  color: ${(props) => props.theme.dayText};
  font-size: 12px;
  margin-bottom: ${(props) => props.theme.grid.half}px;

  div + div {
    margin-left: ${(props) => props.theme.grid.quarter}px;
  }
`;

export default function DailyHabitsList(props) {
  if (props.dailyHabits && props.dailyHabits.length > 0) {
    return (
      <StyledDailyHabitsList>
        {props.dailyHabits.map((dailyHabit, index) => {
          return (
            dailyHabit.isEnabled && (
              <DailyHabit key={dailyHabit._id} dailyHabit={dailyHabit} />
            )
          );
        })}
      </StyledDailyHabitsList>
    );
  } else return <React.Fragment />;
}
