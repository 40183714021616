import React, { useState } from "react";
import styled from "styled-components";

import { Switch } from "antd";
// import Skeleton from "react-loading-skeleton";
import CalSetting from "./CalSetting";

const StyledSettingsModal = styled.div`
  padding: ${(props) => `${props.theme.grid.half}px`};
`;

const StyledSettingsSection = styled.div`
  padding: ${(props) => `${props.theme.grid.half}px`};
  border-radius: ${(props) => props.theme.borderRadius};
  border: 1px solid ${(props) => props.theme.colors.N30};
  background-color: ${(props) => props.theme.colors.N10};
  margin-top: ${(props) => `${props.theme.grid.full}px`};
`;

export default function CalModal(props) {
  return (
    <StyledSettingsModal>
      <h2>Settings</h2>
      <StyledSettingsSection>
        <CalSetting />
      </StyledSettingsSection>
      <StyledSettingsSection>
        <DevModeSetting />
      </StyledSettingsSection>
    </StyledSettingsModal>
  );
}

function DevModeSetting(props) {
  const [devState, setDevState] = useState(
    JSON.parse(localStorage.getItem("devMode"))
  );

  const toggleDevState = () => {
    localStorage.setItem("devMode", !devState);
    setDevState(!devState);
    setTimeout(() => {
      document.location.reload();
    }, 1000);
  };

  return (
    <>
      <h3>Dev mode</h3>
      Enabled?{" "}
      <Switch size="small" checked={devState} onClick={toggleDevState}></Switch>
    </>
  );
}
