import React from "react";
import GoogleLogin from "react-google-login";
import { Button, message } from "antd";
import styled from "styled-components";
import { CalModifier } from "../data/modifiers";
import client from "../client";

import { LoadingOutlined } from "@ant-design/icons";
const StyledInfo = styled.p`
  margin: ${(props) => `${props.theme.grid.half}px 0`};
`;

const StyledActions = styled.div`
  button + button {
    margin-left: ${(props) => `${props.theme.grid.quarter}px`}};
  }
`;

class CalAuth extends React.Component {
  state = {
    isAuthorising: false,
  };
  onSuccess = (code) => {
    message.success("Authorised successfully, now retrieving token...");
    client.mutate({ ...CalModifier.setGCalToken(code.code) });
  };
  onFailure = (obj) => {
    console.log(obj);
    const errors = {
      popup_closed_by_user:
        "You closed the authorisation popup before authorisation was completed",
      idpiframe_initialization_failed: obj.details,
    };
    message.error(errors[obj.error]);
    this.setState({ isAuthorising: false });
  };
  render() {
    return (
      <React.Fragment>
        <StyledInfo>
          Clicking the button below will ask you to login to your Google account
          and provide Dailydo with access to read your calendar events.
        </StyledInfo>
        <StyledActions>
          <GoogleLogin
            clientId="737617185047-ll9h0q7sh0u73b6ppu214k7c9ioru54q.apps.googleusercontent.com"
            buttonText={
              this.state.isAuthorising ? <LoadingOutlined spin /> : "Authorise"
            }
            disabled={this.state.isAuthorising}
            onSuccess={this.onSuccess}
            onFailure={this.onFailure}
            responseType="code"
            scope="https://www.googleapis.com/auth/calendar.events"
            accessType="offline"
            style={{}}
            className="ant-btn ant-btn-primary"
            prompt="consent"
            onRequest={() => {
              this.setState({ isAuthorising: true });
            }}
          />
          <Button onClick={this.props.onCancel}>Cancel</Button>
        </StyledActions>
      </React.Fragment>
    );
  }
}

export default CalAuth;
