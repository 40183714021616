import React from "react";
import styled, { withTheme } from "styled-components";
import { useQuery } from "@apollo/react-hooks";
import { GET_LINKS, GetLinksQuery } from "../queries/LinkQueries";
import LinkItem from "./LinkItem";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Button } from "antd";
import { LoadingOutlined, ReloadOutlined } from "@ant-design/icons";

const StyledLinksList = styled.ul`
  list-style-type:none
  margin:0;
  padding:0
`;

function LinkList(props) {
  const { status /*, limit*/ } = props;
  const { variables } = GetLinksQuery({ status });
  const { loading, error, data, fetchMore, networkStatus } = useQuery(
    GET_LINKS,
    {
      variables,
    }
  );

  if (error) return error;
  if (loading) {
    return (
      <StyledLinksList>
        <SkeletonTheme
          color={props.theme.contextBg}
          highlightColor={props.theme.taskBgHover}
        >
          <Skeleton width="100%" height="53px" count={5} />
        </SkeletonTheme>
      </StyledLinksList>
    );
  }

  const links = data.links || [];

  if (links && links.length) {
    return (
      <StyledLinksList>
        {links.map((l, i) => {
          return <LinkItem {...l} key={i} />;
        })}
        {status === "read" && (
          <Button
            style={{
              display: "block",
              margin: "20px auto",
            }}
            size="small"
            icon={networkStatus === 3 ? LoadingOutlined : ReloadOutlined}
            disabled={loading}
            onClick={() => {
              fetchMore({
                variables: {
                  offset: links.length,
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev;
                  return Object.assign({}, prev, {
                    links: [...prev.links, ...fetchMoreResult.links],
                  });
                },
              });
            }}
          >
            Load More
          </Button>
        )}
      </StyledLinksList>
    );
  } else return "No links found...";
}

export default withTheme(LinkList);
