import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ApolloProvider } from "@apollo/react-hooks";
import client from "./client";
import LinksPage from "./components/LinksPage";
import SignupForm from "./components/SignupForm";
import CalAuth from "./components/CalAuth";
import styled, { ThemeProvider } from "styled-components";
import { lightTheme } from "./styles/themes";
import ModalWrapper from "./components/ModalWrapper";
import DoneTasksPage from "./components/DoneTasksPage";
import TaskLayoutPage from "./components/TaskPageLayout";
import Home from "./components/Home";

// import NotFound from "./NotFound";

const StyledApp = styled.div`
  background-color: ${(props) => props.theme.colors.N0};
  color: ${(props) => props.theme.colors.N800};
`;

//            <Route component={NotFound} />

const Router = () => (
  <BrowserRouter>
    <Switch>
      <ThemeProvider theme={lightTheme}>
        <ApolloProvider client={client}>
          <StyledApp>
            <Route
              exact
              path="/"
              render={() => {
                return (
                  <TaskLayoutPage>
                    <Home />
                  </TaskLayoutPage>
                );
              }}
            />
            <Route exact path="/signup" component={SignupForm} />
            <Route exact path="/cal" component={CalAuth} />
            <Route
              exact
              path="/done"
              render={() => {
                return (
                  <TaskLayoutPage>
                    <DoneTasksPage />
                  </TaskLayoutPage>
                );
              }}
            />
            <Route exact path="/read" component={LinksPage} />
            <Route exact path="/read/:linkId" component={LinksPage} />
            <ModalWrapper />
          </StyledApp>
        </ApolloProvider>
      </ThemeProvider>
    </Switch>
  </BrowserRouter>
);

export default Router;
