import React from "react";
import moment from "moment-timezone";
import { withTheme } from "styled-components";
import ContextItem from "./ContextItem";
import {
  ClockCircleOutlined,
  CheckCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";

const DateTypes = {
  scheduled: <ClockCircleOutlined />,
  completed: <CheckCircleOutlined />,
  due: <WarningOutlined />,
};
const EmptyDateValue = {
  scheduled: "Not scheduled",
  completed: "Not completed",
  due: "No due date",
};

const getDateColor = (type, daysUntilFunc, theme) => {
  switch (type) {
    case "scheduled":
      return null;
    case "completed":
      return theme.colors.G400;
    case "due":
      const daysUntil = daysUntilFunc();
      if (daysUntil === null) {
        return theme.contextText;
      } else if (daysUntil < 1) {
        return theme.colors.R400;
      } else if (daysUntil > 2) {
        return theme.colors.N200;
      } else {
        return theme.colors.Y400;
      }
    default:
      return theme.colors.N800;
  }
};

function TaskDate(props) {
  let returnValue;
  const dateFormat = props.showTime ? "ddd, MMM Do [at] h:mm a" : "ddd, MMM Do";

  if (moment(props.date, "YYYY-MM-DD").isValid()) {
    returnValue = (
      <ContextItem
        color={getDateColor(
          props.type,
          () => {
            return moment(props.date).diff(moment(), "days");
          },
          props.theme
        )}
        icon={!props.hideIcon && DateTypes[props.type]}
        noBg={props.noBg}
        highlightOnHover={props.highlightOnHover}
        isActive={props.isActive}
      >
        {moment(props.date).format(dateFormat)}
      </ContextItem>
    );
  } else {
    returnValue = (
      <ContextItem
        icon={!props.hideIcon && DateTypes[props.type]}
        noBg={props.noBg}
        highlightOnHover={props.highlightOnHover}
        isActive={props.isActive}
      >
        {EmptyDateValue[props.type]}
      </ContextItem>
    );
  }

  return returnValue;
}

export default withTheme(TaskDate);
