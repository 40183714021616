import React from "react";
import moment from "moment-timezone";
import styled from "styled-components";
import { useQuery } from "@apollo/react-hooks";
import { GET_EVENT } from "../data/queries";
import EventAttendeeList from "./EventAttendeeList";
// import Linkify from "linkifyjs/react";
import EventRespondButtons from "./EventRespondButtons";
import ReactHtmlParser from "react-html-parser";
import {
  LoadingOutlined,
  UserOutlined,
  HomeOutlined,
  ClockCircleOutlined,
  AlignLeftOutlined,
  SendOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import ModalRow from "./ModalRow";
import EventResponseIcon from "./EventResponseIcon";

const StyledEventDescription = styled.div`
  color: ${(props) => `${props.theme.eventTextColor}`};
  cursor: default;
  font-size: 12px;
  word-wrap: break-word;
  word-break: break-word;
  white-space: pre-line;
  max-height: 250px;
  overflow: scroll;
`;
// word-break: break-all;

const filterAttendees = (attendees, responseStatus) => {
  if (!attendees || !attendees.length) return [];
  if (responseStatus && responseStatus.length > 0) {
    return attendees.filter(
      (attendee) =>
        !attendee.resource && responseStatus.includes(attendee.responseStatus)
    );
  } else {
    return attendees.filter((attendee) => !attendee.resource);
  }
};

const StyledEventOverview = styled.div`
  padding: ${(props) => `${props.theme.grid.half}px`};
`;

export default function EventOverview(props) {
  const { /*onCancel, */ modalProps } = props;
  // console.log(modalProps);

  const { data, error, loading } = useQuery(GET_EVENT, {
    variables: {
      _id: modalProps.eventId,
    },
  });
  // if (loading) return <React.Fragment />;

  if (error) return `Error! ${error.message}`;
  // if (!data || !data.event) return `Error! No data returned`;

  // console.log(data);
  const event = (data && data.event) || {}; //GetOneTaskFromCache(modalProps.taskId);

  return (
    <StyledEventOverview>
      <ModalRow
        isLoading={loading}
        skeletonHeight={22}
        icon={
          event.isOptimistic ? (
            <LoadingOutlined style={{ color: "grey" }} />
          ) : (
            <EventResponseIcon status={event.myResponseStatus} />
          )
        }
        contentStyle={{ marginRight: 20 }}
      >
        {event.summary}
      </ModalRow>
      <ModalRow
        isLoading={loading}
        skeletonHeight={22}
        icon={<ClockCircleOutlined />}
      >
        {!event.isAllDay ? (
          <React.Fragment>
            {moment(event.startDateTime).format("h:mm A")}&nbsp;–&nbsp;
            {moment(event.endDateTime).format("h:mm A")}
          </React.Fragment>
        ) : (
          "All Day"
        )}
      </ModalRow>
      <ModalRow isLoading={loading} skeletonHeight={22} icon={<HomeOutlined />}>
        <EventAttendeeList
          attendees={event.resources}
          linkify={true}
          emptyState="No location"
        />
      </ModalRow>
      <ModalRow
        isLoading={loading}
        skeletonHeight={22}
        icon={<VideoCameraOutlined />}
      >
        <span style={{ fontSize: 12 }}>
          {(event.zoomUrl && <a href="{event.zoomUrl}">{event.zoomUrl}</a>) ||
            "Not a zoom meeting"}
        </span>
      </ModalRow>
      <ModalRow icon={<SendOutlined />} centerIcon={true}>
        <EventRespondButtons event={event} />
      </ModalRow>
      <ModalRow
        isLoading={loading}
        skeletonHeight={22}
        skeletonCount={3}
        skeletonWidth={150}
        icon={<UserOutlined />}
      >
        <EventAttendeeList
          attendees={filterAttendees(event.attendees, [
            "accepted",
            "declined",
            "tentative",
            "needsAction",
          ])}
        />
      </ModalRow>

      <ModalRow
        icon={<AlignLeftOutlined />}
        isLoading={loading}
        skeletonHeight={44}
      >
        <StyledEventDescription>
          {event.description
            ? ReactHtmlParser(event.description)
            : "No description"}
        </StyledEventDescription>
      </ModalRow>
    </StyledEventOverview>
  );
}
