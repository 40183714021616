import useMousetrap from "./MousetrapHook";
import React from "react";
import { useInlineActions } from "../stores/InlineStore";
import { useClientResetActions } from "../stores/ClientResetStore";
import { useChunkActions } from "../stores/ChunkStore";

export default function KeyboardShortcuts(props) {
  const [, inlineActions] = useInlineActions();
  const [, clientResetActions] = useClientResetActions();
  const [, chunkActions] = useChunkActions();
  useMousetrap("i", () => {
    inlineActions.open("inbox_0");
  });
  useMousetrap("n", () => {
    const currentChunk = chunkActions.getCurrentChunk();

    if (!currentChunk || currentChunk.__typename === "Event") {
      inlineActions.open("inbox_0");
      console.log("currently an event or no current chunk");
    } else if (currentChunk.id) {
      inlineActions.open(`${currentChunk.startDateTime}_last`);
    }
  });
  useMousetrap("r", () => {
    clientResetActions.reset();
  });

  return <React.Fragment />;
}
