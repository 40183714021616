import React from "react";
import { createStore, createHook } from "react-sweet-state";
import TaskOverview from "../components/TaskOverview";
import EventOverview from "../components/EventOverview";
import SettingsModal from "../components/SettingsModal";
import HabitsOverview from "../components/HabitsOverview";
import LinkOverview from "../components/LinkOverview";
import StandupOverview from "../components/StandupOverview";

const Store = createStore({
  initialState: { isOpen: false, openModalName: null, openModalProps: null },
  actions: {
    open: (modalName, modalProps) => ({ setState }) => {
      setState({
        isOpen: true,
        openModalName: modalName,
        openModalProps: modalProps,
      });
      console.log(`opened modal ${modalName}`);
    },
    close: () => ({ setState }) => {
      setState({ isOpen: false });
    },
    clearOpenModal: () => ({ setState }) => {
      setState({ openModalName: null, openModalProps: null });
    },
    getOpenModal: () => ({ getState, actions }) => {
      const modals = {
        settings: <SettingsModal onCancel={actions.close} />,
        habitsOverview: <HabitsOverview onCancel={actions.close} />,
        standupOverview: <StandupOverview onCancel={actions.close} />,
        taskOverview: (
          <TaskOverview
            onCancel={actions.close}
            modalProps={getState().openModalProps}
          />
        ),
        eventOverview: (
          <EventOverview
            onCancel={actions.close}
            modalProps={getState().openModalProps}
          />
        ),
        linkOverview: (
          <LinkOverview
            onCancel={actions.close}
            modalProps={getState().openModalProps}
          />
        ),
      };

      const openModalName = getState().openModalName;
      if (modals[openModalName]) {
        return modals[openModalName];
      } else return <React.Fragment />;
    },
  },
});

export const useModalStore = createHook(Store);
export const useModalActions = createHook(Store, { selector: null });
