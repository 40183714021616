import React, { useState } from "react";
import styled from "styled-components";
import { Icon, Button } from "antd";
import { lightTheme } from "../styles/themes";
import { GetOneLinkFromCache, UPDATE_LINK } from "../queries/LinkQueries";
import { useMutation } from "@apollo/react-hooks";

const StyledStarRating = styled.div`
  display: flex;
`;
const StarList = styled.div``;
const StarReset = styled.div`
  margin-left: ${props => props.theme.grid.half}px;
`;

export default function StarRating(props) {
  const { _id, max = 5, value, name } = props;

  const [hoverValue, setHoverValue] = useState(null);
  const [updateLink] = useMutation(UPDATE_LINK);

  const setRating = function(rating) {
    updateLink({
      variables: { _id, rating },
      optimisticResponse: {
        updateLink: { ...GetOneLinkFromCache(_id), rating, isOptimistic: true }
      }
    });
  };

  const onChange = function(e) {
    e.target.value && setRating(e.target.value);
  };

  let stars = [];
  for (let i = 1; i < max + 1; i++) {
    stars.push(
      <SingleStar
        key={i}
        name={`${name}${i}`}
        isSelected={i === value}
        isHighlighted={hoverValue ? i <= hoverValue : i <= value}
        value={i}
        setHoverValue={setHoverValue}
        onChange={onChange}
      />
    );
  }

  return (
    <StyledStarRating>
      <StarList>{stars}</StarList>
      <StarReset>
        <Button
          size="small"
          type="link"
          onClick={() => {
            setRating(null);
          }}
        >
          Reset
        </Button>
      </StarReset>
    </StyledStarRating>
  );
}

const StyledSingleStar = styled.div`
  display: inline-block;
  & + & {
    margin-left: ${props => props.theme.grid.quarter}px;
  }
  font-size: 18px;
  label {
    cursor: pointer;
  }
  input {
    display: none;
  }
`;

function SingleStar(props) {
  const {
    isHighlighted,
    isSelected,
    value,
    name,
    setHoverValue,
    onChange
  } = props;
  const starColor = isHighlighted
    ? lightTheme.colors.Y400
    : lightTheme.colors.N50;
  return (
    <StyledSingleStar isHighlighted={isHighlighted}>
      <label
        htmlFor={name}
        onMouseEnter={() => {
          setHoverValue(value);
        }}
        onMouseLeave={() => {
          setHoverValue(null);
        }}
      >
        <Icon type="star" theme="twoTone" twoToneColor={starColor}></Icon>
      </label>
      <input
        type="radio"
        id={name}
        name={name}
        value={value}
        checked={isSelected}
        onChange={onChange}
      />
    </StyledSingleStar>
  );
}
