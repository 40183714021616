import React from "react";
import { Checkbox } from "antd";
import styled from "styled-components";
import { LoadingOutlined } from "@ant-design/icons";

const StyledSpinner = styled.div`
  margin-right: ${(props) => props.theme.grid.half}px;
  min-width: 16px;
  min-height: 16px;
`;

export default function CheckboxOpt(props) {
  const { isLoading, isChecked, isDisabled, onChange } = props;

  if (isLoading)
    return (
      <StyledSpinner>
        <LoadingOutlined style={{ color: "grey" }} />
      </StyledSpinner>
    );
  return (
    <Checkbox
      checked={isChecked}
      onChange={onChange}
      style={{ marginRight: 10 }}
      disabled={isDisabled}
    />
  );
}
