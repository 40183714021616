import gql from "graphql-tag";
import client from "../client";
import moment from "moment-timezone";
import { reapplyLocalPos, getWeekDays, combineItems } from "../helpers";
import taskFilterFuncs from "./taskfilterfuncs";

export let TaskHelpers = {};
let EventHelpers = {};
let DailyHabitHelpers = {};
let AttendeeHelpers = {};
let DayHelpers = {};
let HabitHelpers = {};
export let SetHelpers = {};

TaskHelpers.fragments = {
  info: gql`
    fragment TaskInfo on Task {
      _id
      name
      isDone
      scheduledDate
      dueDate
      createdAt
      userId
      isOptimistic
      isSoftRemove
      localPos
      completedAt
      desc
    }
  `,
  softRemove: gql`
    fragment TaskSoftRemove on Task {
      _id
      isSoftRemove
    }
  `,
};

AttendeeHelpers.fragments = {
  info: gql`
    fragment AttendeeInfo on Attendee {
      email
      displayName
      resource
      responseStatus
      organizer
      self
    }
  `,
};

EventHelpers.fragments = {
  info: gql`
    fragment EventInfo on Event {
      _id
      description
      summary
      url
      startDate
      endDate
      startDateTime
      endDateTime
      myResponseStatus
      duration {
        days
        hrs
        mins
        formatted
      }
      isAllDay
      location
      isOptimistic
      attendees {
        ...AttendeeInfo
      }
      attendeeStats {
        total
        accepted
      }
      resources {
        displayName
        responseStatus
      }
      warnings {
        resourceDeclined
        conflict
      }
      zoomUrl
    }
    ${AttendeeHelpers.fragments.info}
  `,
};

HabitHelpers.fragments = {
  info: gql`
    fragment HabitInfo on Habit {
      _id
      name
      userId
      isOptimistic
      days
      highStreak
      currentStreak
      isEnabled
    }
  `,
};

DailyHabitHelpers.fragments = {
  info: gql`
    fragment DailyHabitInfo on DailyHabit {
      _id
      habitId
      name
      date
      userId
      completedHabitId
      completedAt
      streak
      isDone
      isOptimistic
      isEnabled
    }
  `,
};

DayHelpers.fragments = {
  info: gql`
    fragment DayInfo on Day {
      _id
      date
      startDateTime
      endDateTime
      title
      tasks {
        ...TaskInfo
      }
      dailyHabits {
        ...DailyHabitInfo
      }
      events {
        ...EventInfo
      }
      allDayEvents {
        ...EventInfo
      }
    }
    ${TaskHelpers.fragments.info}
    ${DailyHabitHelpers.fragments.info}
    ${EventHelpers.fragments.info}
  `,
  justTasks: gql`
    fragment DayJustTasks on Day {
      _id
      tasks {
        ...TaskInfo
      }
    }
    ${TaskHelpers.fragments.info}
  `,
};

SetHelpers.fragments = {
  info: gql`
    fragment SetInfo on Set {
      _id
      tasks {
        ...TaskInfo
      }
      dailyHabits {
        ...DailyHabitInfo
      }
      events {
        ...EventInfo
      }
      allDayEvents {
        ...EventInfo
      }
    }
    ${TaskHelpers.fragments.info}
    ${DailyHabitHelpers.fragments.info}
    ${EventHelpers.fragments.info}
  `,
};

export const GET_SET_BY_RANGE = gql`
  query setByRange($startDate: Date!, $endDate: Date!, $timezone: String!) {
    setByRange(startDate: $startDate, endDate: $endDate, timezone: $timezone) {
      ...SetInfo
    }
  }
  ${SetHelpers.fragments.info}
`;

export const GET_TASKS_BY_RANGE = gql`
  query tasksByRange($startDate: Date!, $endDate: Date!, $timezone: String!) {
    tasksByRange(
      startDate: $startDate
      endDate: $endDate
      timezone: $timezone
    ) {
      ...TaskInfo
    }
  }
  ${TaskHelpers.fragments.info}
`;
export const GET_EVENTS_BY_RANGE = gql`
  query eventsByRange($startDate: Date!, $endDate: Date!, $timezone: String!) {
    eventsByRange(
      startDate: $startDate
      endDate: $endDate
      timezone: $timezone
    ) {
      ...EventInfo
    }
  }
  ${EventHelpers.fragments.info}
`;

export const GET_DAILY_HABITS_BY_RANGE = gql`
  query dailyHabitsByRange(
    $startDate: Date!
    $endDate: Date!
    $timezone: String!
  ) {
    dailyHabitsByRange(
      startDate: $startDate
      endDate: $endDate
      timezone: $timezone
    ) {
      ...DailyHabitInfo
    }
  }
  ${DailyHabitHelpers.fragments.info}
`;

export const GET_TASKS = gql`
  query tasks(
    $isDone: Boolean!
    $orderBy: AllowedTasksSort
    $offset: Int
    $limit: Int
    $isUnscheduled: Boolean
  ) {
    tasks(
      isDone: $isDone
      isUnscheduled: $isUnscheduled
      orderBy: $orderBy
      offset: $offset
      limit: $limit
    ) {
      ...TaskInfo
    }
  }
  ${TaskHelpers.fragments.info}
`;

export const GET_HABITS_OVERVIEW = gql`
  query habits {
    habits {
      ...HabitInfo
    }
  }
  ${HabitHelpers.fragments.info}
`;

export const GET_DAILY_HABITS = gql`
  query dailyHabits($numDays: Int) {
    dailyHabits(numDays: $numDays) {
      ...DailyHabitInfo
    }
  }
  ${DailyHabitHelpers.fragments.info}
`;

export const UPDATE_HABIT = gql`
  mutation updateHabit(
    $_id: String!
    $name: String
    $isEnabled: Boolean
    $days: [DayOfTheWeek]
  ) {
    updateHabit(_id: $_id, name: $name, isEnabled: $isEnabled, days: $days) {
      ...HabitInfo
    }
  }
  ${HabitHelpers.fragments.info}
`;

export const GET_STANDUP = gql`
  query standup($date: Date!, $timezone: String!) {
    standup(date: $date, timezone: $timezone) {
      date
      tasks {
        ...TaskInfo
      }
      dailyHabits {
        ...DailyHabitInfo
      }
      events {
        ...EventInfo
      }
    }
  }
  ${TaskHelpers.fragments.info}
  ${DailyHabitHelpers.fragments.info}
  ${EventHelpers.fragments.info}
`;

export const GET_DAYS = gql`
  query fullDays(
    $startDate: Date!
    $endDate: Date!
    $timezone: String!
    $isFullSet: Boolean
  ) {
    fullDays(
      startDate: $startDate
      endDate: $endDate
      timezone: $timezone
      isFullSet: $isFullSet
    ) {
      ...DayInfo
    }
  }
  ${DayHelpers.fragments.info}
`;
// isDone: false

export const MOVE_TASK = gql`
  mutation moveTask($_id: String!, $scheduledDate: DateTime, $localPos: Int) {
    updateTask(_id: $_id, scheduledDate: $scheduledDate, localPos: $localPos) {
      ...TaskInfo
    }
  }
  ${TaskHelpers.fragments.info}
`;

export const UPDATE_TASK = gql`
  mutation updateTask(
    $_id: String!
    $name: String
    $isDone: Boolean
    $dueDate: Date
    $scheduledDate: DateTime
    $localPos: Int
    $desc: String
  ) {
    updateTask(
      _id: $_id
      name: $name
      isDone: $isDone
      scheduledDate: $scheduledDate
      dueDate: $dueDate
      localPos: $localPos
      desc: $desc
    ) {
      ...TaskInfo
    }
  }
  ${TaskHelpers.fragments.info}
`;

export const CREATE_TASK = gql`
  mutation createTask(
    $name: String!
    $localPos: Int!
    $scheduledDate: DateTime
    $dueDate: Date
    $desc: String
  ) {
    createTask(
      name: $name
      scheduledDate: $scheduledDate
      dueDate: $dueDate
      desc: $desc
      localPos: $localPos
    ) {
      ...TaskInfo
    }
  }
  ${TaskHelpers.fragments.info}
`;

export const TOGGLE_COMPLETE_HABIT = gql`
  mutation toggleCompleteHabit($habitId: String!, $date: Date!) {
    toggleCompleteHabit(habitId: $habitId, date: $date) {
      ...DailyHabitInfo
    }
  }
  ${DailyHabitHelpers.fragments.info}
`;
export const DELETE_TASK = gql`
  mutation deleteTask($_id: String!) {
    deleteTask(_id: $_id) {
      _id
      scheduledDate
      isDone
    }
  }
`;
export const RESPOND_EVENT = gql`
  mutation respondToEvent($_id: String!, $myResponseStatus: ResponseStatus!) {
    respondToEvent(_id: $_id, myResponseStatus: $myResponseStatus) {
      _id
      myResponseStatus
      isOptimistic
      attendees {
        ...AttendeeInfo
      }
    }
  }
  ${AttendeeHelpers.fragments.info}
`;

export const GET_TASK = gql`
  query task($_id: String!) {
    task(_id: $_id) {
      ...TaskInfo
    }
  }
  ${TaskHelpers.fragments.info}
`;

export const GET_EVENT = gql`
  query event($_id: String!) {
    event(_id: $_id) {
      ...EventInfo
    }
  }
  ${EventHelpers.fragments.info}
`;

export const GET_CALENDAR = gql`
  query primaryCalendar {
    primaryCalendar {
      _id
      summary
    }
  }
`;

export const LOGIN_USER = gql`
  mutation loginUser($email: String!, $password: String!) {
    loginUser(email: $email, password: $password) {
      _id
      firstName
      lastName
      email
      token
    }
  }
`;

export const SIGNUP_USER = gql`
  mutation createUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
  ) {
    createUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
    ) {
      _id
      firstName
      lastName
      email
      token
    }
  }
`;

export const SET_GCAL_TOKEN = gql`
  mutation setGCalToken($code: String!) {
    setGCalToken(code: $code) {
      _id
      summary
    }
  }
`;

export const REVOKE_GCAL_TOKEN = gql`
  mutation revokeGCalToken {
    revokeGCalToken {
      status
    }
  }
`;

export const GET_EVENTS = gql`
  query events($startDate: Date!, $endDate: Date!, $timezone: String!) {
    events(startDate: $startDate, endDate: $endDate, timezone: $timezone) {
      ...EventInfo
    }
  }
  ${EventHelpers.fragments.info}
`;

export const QueryDays = ({ startDate, endDate, isFullSet }) => {
  return {
    query: GET_DAYS,
    variables: { startDate, endDate, timezone: moment.tz.guess(), isFullSet },
    notifyOnNetworkStatusChange: true,
  };
};

export const QueryDoneTasks = {
  query: GET_TASKS,
  variables: { isDone: true, orderBy: "completedAt_DESC", limit: 10 },
  notifyOnNetworkStatusChange: true,
};

export const QueryCalendar = {
  query: GET_CALENDAR,
  notifyOnNetworkStatusChange: true,
};

export const GetOneTaskFromCache = (taskId) => {
  return client.readFragment({
    fragment: TaskHelpers.fragments.info,
    id: `Task:${taskId}`,
  });
};

export const GetCurrentSetFromCache = () => {
  return GetOneSetFromCache(moment().format("YYYY-MM-DD"));
};

export const GetOneSetFromCache = (setId) => {
  return client.readFragment({
    fragment: SetHelpers.fragments.info,
    fragmentName: "SetInfo",
    id: `Set:${setId}`,
  });
};

export const GetOneDayFromCache = (dayId) => {
  return client.readFragment({
    fragment: DayHelpers.fragments.info,
    fragmentName: "DayInfo",
    id: `Day:${dayId}`,
  });
};

// export const WriteOneDayIntoCache = (dayId, day) => {
//   return client.writeFragment({
//     id: `Day:${dayId}`,
//     fragment: DayHelpers.fragments.justTasks,
//     fragmentName: "DayJustTasks",
//     data: { _id: day._id, tasks: day.tasks, __typename: "Day" },
//   });
// };
export const WriteSetIntoCache = ({ setId, set }) => {
  return client.writeFragment({
    id: `Set:${setId}`,
    fragment: SetHelpers.fragments.info,
    fragmentName: "SetInfo",
    data: set,
  });
};

export const GetDoneTasksFromCache = (filterTaskId) => {
  const tasksFromQuery = client.readQuery(QueryDoneTasks);
  if (tasksFromQuery && tasksFromQuery.tasks) {
    const tasks = tasksFromQuery.tasks;
    return filterTaskId ? tasks.filter((t) => t._id !== filterTaskId) : tasks;
  } else return [];
};

export const GetDaysFromCache = () => {
  const weekDays = getWeekDays();
  const daysFromQuery = client.readQuery(
    QueryDays({
      startDate: weekDays[0],
      endDate: weekDays[weekDays.length - 1],
      isFullSet: true,
    })
  );
  return daysFromQuery;
};

export const InsertOrRemoveTaskFromCache = ({
  task,
  action,
  chunkInfo,
  simulate,
}) => {
  const isInsert = action === "insert";
  const setId = moment().format("YYYY-MM-DD");
  const set = GetOneSetFromCache(setId);
  let fullDayTasks = [];

  let dayTasks = set.tasks
    .filter(taskFilterFuncs(chunkInfo, "day"))
    .filter((t) => t._id !== task._id);
  let chunkTasks = [...dayTasks.filter(taskFilterFuncs(chunkInfo))];

  // const newTasks = day.tasks.filter((t) => t._id !== task._id);
  if (isInsert) {
    chunkTasks.splice(task.localPos, 0, task);
    chunkTasks.forEach(function (t, i) {
      this[i].localPos = i;
    }, chunkTasks); // reapply chunk's local pos so they get sorted correctly
    let chunkTaskIds = chunkTasks.map((t) => t._id);
    fullDayTasks = combineItems({
      tasks: [
        ...dayTasks.filter((t) => !chunkTaskIds.includes(t._id)),
        ...chunkTasks,
      ],
    });
  } else {
    fullDayTasks = dayTasks;
  }
  const setTasks = reapplyLocalPos({
    // tasks: chunkTasks,
    set: set,
    tasks: fullDayTasks,
    removeTaskId: !isInsert ? task._id : "",
    simulate,
  }).tasks;
  return setTasks;
};
// export const InsertOrRemoveTaskFromCache = ({ task, action }) => {
//   const isInsert = action === "insert";
//   if (task.isDone) {
//     const allTasks = GetDoneTasksFromCache(task._id);
//     isInsert && allTasks.splice(task.localPos, 0, task);
//     client.writeQuery({
//       ...QueryDoneTasks,
//       data: {
//         tasks: allTasks,
//       },
//     });
//   } else {
//     const day = getDayFromTask({ task, verifyExists: !isInsert });
//     if (day) {
//       const newTasks = day.tasks.filter((t) => t._id !== task._id);
//       isInsert && newTasks.splice(task.localPos, 0, task);
//       day.tasks = reapplyLocalPos({
//         tasks: newTasks,
//         scheduledDate: task.scheduledDate,
//       });
//       WriteOneDayIntoCache(day._id, day);
//     }
//   }
// };

export const InsertTaskIntoCache = ({ task, chunkInfo, simulate }) =>
  InsertOrRemoveTaskFromCache({ task, action: "insert", chunkInfo, simulate });

export const RemoveTaskFromCache = ({ task, chunkInfo, simulate }) =>
  InsertOrRemoveTaskFromCache({ task, action: "remove", chunkInfo, simulate });

// export const UpdateTaskCache = ({ prevTask, task }) => {
//   // prevTask && prevTask._id && RemoveTaskFromCache(prevTask);
//   // InsertTaskIntoCache(task);
// };

export const softRemoveTask = ({ taskId }) => {
  return client.writeFragment({
    id: `Task:${taskId}`,
    fragment: TaskHelpers.fragments.softRemove,
    fragmentName: "TaskSoftRemove",
    data: { _id: taskId, isSoftRemove: true, __typename: "Task" },
  });
};
