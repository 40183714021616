import React from "react";
import styled from "styled-components";

import LinkCheckbox from "./LinkCheckbox";
import moment from "moment-timezone";
import pluralize from "pluralize";
// import { useModalStore } from "../stores/ModalStore";
import { NavLink } from "react-router-dom";
import { wordsToMins } from "../helpers";

// const LINK_STATUS_READ = "read";
const LINK_STATUS_UNREAD = "unread";
// const LINK_STATUS_READING = "reading";

const StyledLink = styled.li`
  margin: ${(props) => `${props.theme.grid.tiny}px`} 0
    ${(props) => `${props.theme.grid.quarter}px`} 0;
  -webkit-user-select: none;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

  a {
    text-decoration: none;
    color: inherit;
    display: block;
    border-radius: ${(props) => props.theme.borderRadius};
  }
  a:hover {
    background-color: ${(props) => props.theme.colors.N30};
  }
  a.active {
    text-decoration: none;
    color: inherit;
    background-color: ${(props) => props.theme.colors.N0};
    box-shadow: 1px 1px 4px ${(props) => props.theme.colors.N50};
  }
`;

const StyledLinkInner = styled.div`
  padding: ${(props) => `${props.theme.grid.quarter}px`};
  display: flex;
`;

const StyledCheckboxWrapper = styled.div`
  margin-left: ${(props) => `${props.theme.grid.quarter}px`};
`;

const StyledLinkContents = styled.div``;

const StyledLinkTitle = styled.div`
  color: inherit;
  font-family: "Markazi Text", serif;
  font-size: 22px;
  line-height: 22px;
  text-decoration: none;
  margin-bottom: 0;
`;
const StyledLinkMeta = styled.div`
  color: ${(props) => props.theme.colors.N80};
  font-size: 12px;
  margin-top: 0;
`;

export default function LinkItem(props) {
  // const [, modalStoreActions] = useModalStore();

  const {
    _id,
    url,
    wordCount,
    numQuotes,
    title,
    status,
    isOptimistic,
    domain,
    createdAt,
    readAt,
  } = props;

  const dayDiff = moment().diff(
    status === LINK_STATUS_UNREAD ? createdAt : readAt,
    "days"
  );

  return (
    <StyledLink>
      <NavLink to={`/read/${_id}`}>
        <StyledLinkInner>
          <StyledCheckboxWrapper>
            <LinkCheckbox
              _id={_id}
              status={status}
              isOptimistic={isOptimistic}
            />
          </StyledCheckboxWrapper>
          <StyledLinkContents>
            <StyledLinkTitle>{title || url}</StyledLinkTitle>
            <StyledLinkMeta>
              {domain && domain}
              {createdAt &&
                ` · ${status === LINK_STATUS_UNREAD ? "added" : "read"} ${
                  dayDiff > 0
                    ? `${dayDiff} ${pluralize("day", dayDiff)} ago`
                    : `today`
                }`}
              {wordCount > 0 &&
                ` · ${wordsToMins(wordCount)} ${pluralize(
                  "min",
                  wordsToMins(wordCount)
                )}`}
              {numQuotes > 0 &&
                ` · ${numQuotes} ${pluralize("quote", numQuotes)}`}
            </StyledLinkMeta>
          </StyledLinkContents>
        </StyledLinkInner>
      </NavLink>
    </StyledLink>
  );
}
