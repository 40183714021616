import React from "react";
import styled from "styled-components";
import Textarea from "react-textarea-autosize";
import { Formik, Form, Field } from "formik";
import {
  // GetOneLinkFromCache,
  CREATE_LINKQUOTE,
  InsertLinkQuoteIntoCache,
} from "../queries/LinkQueries";
import { useMutation } from "@apollo/react-hooks";
import { guidGenerator } from "../helpers";

const StyledLinkQuoteForm = styled.div`
  input,
  textarea {
    width: 100%;
    border: 1px solid ${(props) => props.theme.colors.N80};
    resize: none;
    padding: ${(props) => props.theme.grid.tiny}px
      ${(props) => props.theme.grid.quarter}px;

    &:hover {
      background-color: ${(props) => props.theme.colors.N20};
    }
    &:focus {
      background-color: ${(props) => props.theme.colors.N0};
      border: 1px solid ${(props) => props.theme.colors.N30};
    }
  }
`;

export default function LinkQuoteForm(props) {
  const {
    linkId,
    // isLoading,
    submitOnBlur,
    WrapComponent = React.Fragment,
  } = props;

  // const cachedLink = GetOneLinkFromCache(linkId);
  const [createLinkQuote] = useMutation(CREATE_LINKQUOTE, {
    update(cache, { data: { createQuoteByLinkId } }) {
      InsertLinkQuoteIntoCache(createQuoteByLinkId);
    },
  });

  const onSubmit = function (values, actions) {
    if (values.quote) {
      let updateLinkQuoteVariables = { linkId, quote: values.quote };
      let optimisticLinkQuote = {
        _id: guidGenerator(),
        ...updateLinkQuoteVariables,
        createdAt: new Date(),
        isOptimistic: true,
        __typename: "LinkQuote",
      };

      createLinkQuote({
        variables: updateLinkQuoteVariables,
        optimisticResponse: {
          createQuoteByLinkId: optimisticLinkQuote,
        },
      });
    }
    // fieldRef.current.blur();
  };
  return (
    <WrapComponent>
      <StyledLinkQuoteForm>
        <Formik
          initialValues={{ quote: "" }}
          onSubmit={(values, actions) => {
            onSubmit(values, actions);
            // actions.setSubmitting(false);
            actions.resetForm();
          }}
        >
          {({ isSubmitting, handleSubmit, handleChange, values }) => (
            <Form>
              <Field
                component={Textarea}
                id="quote"
                name="quote"
                value={values.quote}
                onChange={handleChange}
                onBlur={() => {
                  if (submitOnBlur && !isSubmitting) {
                    handleSubmit();
                  }
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13 && e.shiftKey === false) {
                    handleSubmit();
                    if (!isSubmitting) {
                      e.target.blur();
                    }
                    e.preventDefault();
                  }
                }}
                placeholder={"Add a quote"}
              />
            </Form>
          )}
        </Formik>
      </StyledLinkQuoteForm>
    </WrapComponent>
  );
}
