import React from "react";
import { Modal } from "antd";
import { useModalStore } from "../stores/ModalStore";

export default function ModalWrapper(props) {
  const [modalStoreState, modalStoreActions] = useModalStore();
  return (
    <Modal
      visible={modalStoreState.isOpen}
      onCancel={modalStoreActions.close}
      footer={null}
      // closable={false}
      afterClose={modalStoreActions.clearOpenModal}
      destroyOnClose={true}
    >
      {modalStoreActions.getOpenModal()}
    </Modal>
  );
}
