import React from "react";
import styled from "styled-components";
import { Button } from "antd";
import { useClientResetStore } from "../stores/ClientResetStore";
import {
  DisconnectOutlined,
  LoadingOutlined,
  ReloadOutlined
} from "@ant-design/icons";

const StyledConnectionError = styled.div`
  p {
    margin-top: ${props => `${props.theme.grid.half}px`};
  }
  max-width: 300px;
  text-align: center;
  background-color: ${props => props.theme.colors.N20};
  margin: ${props => `${props.theme.grid.full}px`} auto;
  padding: ${props => `${props.theme.grid.full}px`};
  height: 140px;
  border-radius: 4px;
`;

const StyledHeading = styled.div`
  i {
    color: ${props => props.theme.colors.R500};
    font-size: 24px;
  }
  h2 {
    display: inline-block;
    color: ${props => props.theme.colors.R500};
  }
`;

export default function ConnectionError(props) {
  const [
    clientResetStoreState,
    clientResetStoreActions
  ] = useClientResetStore();
  return (
    <StyledConnectionError>
      <StyledHeading>
        <DisconnectOutlined />
      </StyledHeading>
      <p>{props.message ? props.message : "Oops, something went wrong."}</p>
      <Button
        icon={
          clientResetStoreState.isResetting ? (
            <LoadingOutlined />
          ) : (
            <ReloadOutlined />
          )
        }
        onClick={clientResetStoreActions.reset}
        size="small"
      >
        Retry
      </Button>
    </StyledConnectionError>
  );
}
