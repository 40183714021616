import {
  UPDATE_TASK,
  SET_GCAL_TOKEN,
  REVOKE_GCAL_TOKEN,
  TOGGLE_COMPLETE_HABIT,
} from "./queries";
import moment from "moment-timezone";
const uuidv4 = require("uuid/v4");

export const TaskModifier = {
  completeTask: ({ task }) => {
    return {
      mutation: UPDATE_TASK,
      optimisticResponse: {
        __typename: "Mutation",
        updateTask: {
          ...task,
          isDone: !task.isDone,
          isSoftRemove: false,
          completedAt: !task.isDone ? moment().format() : null,
          isOptimistic: true,
        },
      },
      variables: {
        _id: task._id,
        isDone: !task.isDone,
      },
    };
  },
};

export const HabitModifier = {
  toggleCompleteHabit: ({ dailyHabit }) => {
    const toggledState = !dailyHabit.isDone;
    return {
      mutation: TOGGLE_COMPLETE_HABIT,
      optimisticResponse: {
        __typename: "Mutation",
        toggleCompleteHabit: {
          ...dailyHabit,
          streak: null,
          isDone: toggledState,
          completedAt: toggledState ? Date.now() : null,
          completedHabitId: toggledState ? uuidv4() : null,
          isOptimistic: true,
        },
      },
      variables: { habitId: dailyHabit.habitId, date: dailyHabit.date },
      refetchQueries: ["dailyHabits"],
      awaitRefetchQueries: true,
    };
  },
};

export const UserModifier = {
  login(user, callback) {
    console.log("calling login");

    if (user && user.token) {
      console.log("received token");
      //loggin in
      localStorage.setItem("token", user.token);
      localStorage.setItem("firstName", user.firstName);
      localStorage.setItem("userId", user._id);

      // await client.resetStore(); //.then(() => {
      // this.setState({ isLoggedIn: true });
      //});
      callback(true);
    }
  },
  logout(callback) {
    localStorage.removeItem("token");
    localStorage.removeItem("firstName");
    localStorage.removeItem("userId");
    callback(true);
  },
};

export const CalModifier = {
  setGCalToken(code) {
    console.log(`Setting Google Calendar token with code: ${code}`);
    return {
      mutation: SET_GCAL_TOKEN,
      variables: { code: code },
      refetchQueries: ["primaryCalendar", "fullDays"],
    };
  },
  revokeCalToken() {
    console.log(`Revoking token`);
    return {
      mutation: REVOKE_GCAL_TOKEN,
      refetchQueries: ["primaryCalendar", "fullDays"],
    };
  },
};
