import { createStore, createHook } from "react-sweet-state";

const Store = createStore({
  initialState: { draftName: "" },
  actions: {
    setDraftName: (draftName) => ({ setState }) => {
      setState({ draftName });
    },
  },
});

export const useInlineDraftStore = createHook(Store);

// export const useInlineDraftActions = createHook(Store, { selector: null });
