import React from "react";
import moment from "moment-timezone";
import { withTheme } from "styled-components";
import { GetOneTaskFromCache, UPDATE_TASK } from "../data/queries";
import { useMutation } from "@apollo/react-hooks";
import { moveTaskHelper } from "../helpers";
import styled from "styled-components";
import { DatePicker } from "antd";
import { useChunkActions } from "../stores/ChunkStore";

const StyledDate = styled.div`
  display: inline-block;
`;

function TaskDate(props) {
  let returnValue;
  const fieldName = props.fieldName;
  const taskId = props.taskId;
  const truncTime = props.truncTime;
  // const [isFocused, setIsFocused] = useState(false);
  const momentDate = moment(props.date, "YYYY-MM-DD").isValid()
    ? moment(props.date)
    : null;

  const cachedTask = GetOneTaskFromCache(taskId);
  const [updateTask] = useMutation(UPDATE_TASK);
  const [, chunkActions] = useChunkActions();

  const onSubmit = function (date) {
    // if (date) {
    //   date = truncTime ? date.format("YYYY-MM-DD") : date.toDate();
    // }

    // if (!date) return;
    if (!date || !date.isSame(cachedTask[fieldName], "day")) {
      if (fieldName === "scheduledDate") {
        // console.log(getDayNameFromDate({ task: cachedTask }));

        moveTaskHelper({
          sourceInfoObj: chunkActions.getChunkFromTask(cachedTask),
          destInfoObj: chunkActions.getChunkOfSection({
            sectionType: date ? "dateRange" : "inbox",
            date: date ? date.format("YYYY-MM-DD") : "",
          }),
          destPos: 0,
          origTask: cachedTask,
          moveMutation: updateTask,
        });
      } else {
        if (date) {
          date = truncTime ? date.format("YYYY-MM-DD") : date.toDate();
        }
        let updateTaskVariables = { _id: taskId };
        let optimisticTask = { ...cachedTask, isOptimistic: true };
        updateTaskVariables[fieldName] = date;
        optimisticTask[fieldName] = date;

        const optimisticResponse = {};
        optimisticResponse.updateTask = optimisticTask;

        updateTask({
          variables: updateTaskVariables,
          optimisticResponse,
        });
      }
    }
  };

  returnValue = (
    <StyledDate>
      <DatePicker
        format="ddd, MMM Do"
        value={momentDate}
        onChange={(date, dateString) => {
          onSubmit(date);
        }}
      />
    </StyledDate>
  );

  return returnValue;
}

export default withTheme(TaskDate);
