import React from "react";
import styled from "styled-components";
import { QueryCalendar } from "../data/queries";
import { useQuery } from "@apollo/react-hooks";

import { Button, Tag } from "antd";
import CalAuth from "./CalAuth";
import { CalModifier } from "../data/modifiers";
import client from "../client";
import Skeleton from "react-loading-skeleton";

const StyledCalTitle = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-left: auto;
  }
`;
const StyledInfo = styled.div`
  margin: ${(props) => `${props.theme.grid.half}px 0`};
  p {
    &:first-child {
      margin-bottom: 0;
    }
  }
`;

const StyledActions = styled.div`
  button + button {
    margin-left: ${(props) => `${props.theme.grid.quarter}px`}};
  }
`;

export default function CalSetting(props) {
  const { loading, error, data } = useQuery(QueryCalendar.query, QueryCalendar);

  if (error) return `Error! ${error.message}`;

  const tagColor = loading
    ? "lightgrey"
    : data && data.primaryCalendar
    ? "green"
    : "red";
  const tagText = loading
    ? "Waiting"
    : data && data.primaryCalendar
    ? "Connected"
    : "Not connected";

  return (
    <>
      <StyledCalTitle>
        <h3>Google Calendar</h3> <Tag color={tagColor}>{tagText}</Tag>
      </StyledCalTitle>
      {loading ? (
        <>
          <StyledInfo>
            <Skeleton
              width="250px"
              height="22px"
              count={2}
              widthRandomness={0}
            />
          </StyledInfo>
        </>
      ) : data && data.primaryCalendar ? (
        <>
          <StyledInfo>
            <p>Account: {data.primaryCalendar._id}</p>
            <p>Calendar: {data.primaryCalendar.summary}</p>
          </StyledInfo>
          <StyledActions>
            <Button
              type="danger"
              onClick={() => {
                client.mutate({ ...CalModifier.revokeCalToken() });
              }}
              size="small"
            >
              Revoke
            </Button>
          </StyledActions>
        </>
      ) : (
        <React.Fragment>
          <CalAuth onCancel={props.onCancel} />
        </React.Fragment>
      )}
    </>
  );
}
