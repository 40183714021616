import React from "react";
import styled from "styled-components";
import { HabitModifier } from "../data/modifiers";
import { useMutation } from "@apollo/react-hooks";
import {
  LoadingOutlined,
  CheckCircleOutlined,
  QuestionCircleOutlined
} from "@ant-design/icons";

const StyledDailyHabit = styled.div`
  font-size: 12px;
  border: 1px solid ${props => props.theme.taskBorderHover};
  border-radius: ${props => props.theme.borderRadius};
  color: ${props => props.theme.dayText};
  cursor: pointer;
  display: inline-block;
  padding: ${props => props.theme.grid.tiny}px
    ${props => props.theme.grid.quarter}px;
  transition: border 0.2s, background 0.2s, opacity 0.2s, transform 0.2s ease;

  &:hover {
    background-color: ${props => props.theme.taskBgHover};
  }
  span {
    margin-right: ${props => props.theme.grid.quarter}px;
  }

  ${props => {
    if (props.isDone) {
      return `
        background-color: ${props.theme.colors.G500}; 
        border: 1px solid transparent;
        color: ${props.theme.colors.N0};
        &:hover{
          background-color: ${props.theme.colors.G400}; 
        }
      `;
    }
  }}
`;

const StyledStreak = styled.div`
  display: inline-block;
  margin-top: -${props => props.theme.grid.tiny}px;
  margin-bottom: -${props => props.theme.grid.tiny}px;
  margin-left: ${props => props.theme.grid.quarter}px;
  margin-right -${props => props.theme.grid.quarter}px;
  padding: ${props => props.theme.grid.tiny}px ${props =>
  props.theme.grid.quarter}px;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
`;

export default function DailyHabit(props) {
  const iconType = props.dailyHabit.isOptimistic ? (
    <LoadingOutlined />
  ) : props.dailyHabit.isDone ? (
    <CheckCircleOutlined />
  ) : (
    <QuestionCircleOutlined />
  );

  const {
    mutation,
    update,
    optimisticResponse,
    variables,
    refetchQueries,
    awaitRefetchQueries
  } = HabitModifier.toggleCompleteHabit({ dailyHabit: props.dailyHabit });

  const [toggleCompleteHabit] = useMutation(mutation, {
    update,
    optimisticResponse,
    refetchQueries,
    awaitRefetchQueries
  });
  return (
    <StyledDailyHabit
      isDone={props.dailyHabit.isDone}
      onClick={() => {
        if (props.dailyHabit.isOptimistic) {
          return;
        } else toggleCompleteHabit({ variables });
      }}
    >
      {iconType}
      {props.dailyHabit.name}
      {props.dailyHabit.streak && (
        <StyledStreak>{props.dailyHabit.streak}</StyledStreak>
      )}
    </StyledDailyHabit>
  );
}
