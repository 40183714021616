import React from "react";
// import media from "../styles/media-queries";
import NavItem, { StyledNavItem } from "./NavItem";
import styled from "styled-components"; //, { css }
import media from "../styles/media-queries";
import { useInlineActions } from "../stores/InlineStore";
import { useClientResetStore } from "../stores/ClientResetStore";
import { useModalActions } from "../stores/ModalStore";
import { useUserStore } from "../stores/UserStore";
// import { useShowDoneTasksStore } from "../stores/ShowDoneTasksStore";
import { UserModifier } from "../data/modifiers";
import MobileNav from "./MobileNav";
import { withRouter } from "react-router-dom";
import {
  PlusOutlined,
  ReloadOutlined,
  TrophyOutlined,
  // SolutionOutlined,
  SettingOutlined,
  // CheckCircleOutlined,
  // CloseCircleOutlined,
  LogoutOutlined,
} from "@ant-design/icons";

const StyledNavContainer = styled.div``;

const StyledNav = styled.ol`
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;
  ${media.tablet`
    display: none;
`};
${StyledNavItem} + ${StyledNavItem} {
  margin-left: ${(props) => props.theme.grid.half}px;
}}
`;

function Nav(props) {
  const {
    location: { pathname },
  } = props;
  const [, userStoreActions] = useUserStore();
  const [, modalStoreActions] = useModalActions();
  const [, inlineStoreActions] = useInlineActions();
  // const [
  //   showDoneTasksStore,
  //   showDoneTasksStoreActions,
  // ] = useShowDoneTasksStore();
  const [
    clientResetStoreState,
    clientResetStoreActions,
  ] = useClientResetStore();
  const navItems = [
    {
      name: "newTask",
      label: "New Task",
      icon: <PlusOutlined />,
      onClick: () => {
        inlineStoreActions.open("inbox_0");
      },
      mobilePrimary: true,
      hideMobileMenu: true,
      routes: ["/"],
    },
    {
      name: "refresh",
      label: "Refresh",
      icon: <ReloadOutlined />,
      isLoading: clientResetStoreState.isResetting,
      onClick: clientResetStoreActions.reset,
      routes: ["*"],
    },
    {
      name: "habits",
      label: "Habits",
      icon: <TrophyOutlined />,
      onClick: () => {
        modalStoreActions.open("habitsOverview");
      },
      routes: ["/"],
    },
    // {
    //   name: "standup",
    //   label: "Standup",
    //   icon: <SolutionOutlined />,
    //   onClick: () => {
    //     modalStoreActions.open("standupOverview");
    //   },
    //   routes: ["/"],
    // },
    // {
    //   name: "showDone",
    //   label: showDoneTasksStore.show ? "Hide done" : "Show done",
    //   icon: showDoneTasksStore.show ? (
    //     <CloseCircleOutlined />
    //   ) : (
    //     <CheckCircleOutlined />
    //   ),
    //   onClick: () => {
    //     showDoneTasksStoreActions.toggle();
    //   },
    //   routes: ["/"],
    // },
    {
      name: "settings",
      label: "Settings",
      icon: <SettingOutlined />,
      onClick: () => {
        modalStoreActions.open("settings");
      },
      routes: ["/"],
    },
    {
      name: "logout",
      label: "Log out",
      icon: <LogoutOutlined />,
      onClick: () => {
        UserModifier.logout(() => {
          userStoreActions.afterLogout();
        });
      },
      routes: ["*"],
    },
  ];
  return (
    <StyledNavContainer>
      <StyledNav>
        <>
          {navItems.map((i, index) => {
            if (i.routes.includes(pathname) || i.routes.includes("*")) {
              return (
                <NavItem key={index} {...i}>
                  {i.label}
                </NavItem>
              );
            }
            return null;
          })}
        </>
      </StyledNav>

      <MobileNav navItems={navItems} />
    </StyledNavContainer>
  );
}

export default withRouter(Nav);
