import React from "react";
import { Menu, Dropdown } from "antd";
import media from "../styles/media-queries";
import styled from "styled-components";
import NavItem, { StyledNavItem } from "./NavItem";

const StyledMobileNav = styled("div")`
  position: fixed;
  right: ${(props) => props.theme.grid.double}px;
  bottom: ${(props) => props.theme.grid.double}px;
  z-index: 2;
  display: none;
  -webkit-box-shadow: 2px 3px 3px 0px rgba(41, 41, 41, 0.3);
  border-radius: 4px;
  ${media.tablet`
    display: block;
  `};
`;

const StyledMobileMenu = styled(Menu)`
  ${StyledNavItem} {
    width: 100%;
    padding: 10px 15px;
  }
`;

export default function MobileNav(props) {
  let primaryItem = props.navItems.find((n) => n.mobilePrimary);
  let mobileItems = props.navItems.filter((n) => !n.hideMobileMenu);
  let menu = (
    <StyledMobileMenu>
      {mobileItems.map((i, index) => {
        return (
          <NavItem key={index} {...i}>
            {i.label}
          </NavItem>
        );
      })}
    </StyledMobileMenu>
  );
  return (
    <StyledMobileNav>
      <Dropdown.Button
        overlay={menu}
        placement="topRight"
        trigger={["click"]}
        type="primary"
        onClick={() => {
          primaryItem.onClick();
        }}
        size="large"
      >
        {primaryItem.icon}
      </Dropdown.Button>
    </StyledMobileNav>
  );
}
