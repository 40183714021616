import React from "react";
import CheckboxOpt from "./CheckboxOpt";
import {
  UPDATE_LINK,
  UpdateLinkCache,
  GetOneLinkFromCache,
} from "../queries/LinkQueries";
import { useMutation } from "@apollo/react-hooks";

const LINK_STATUS_READ = "read";
const LINK_STATUS_UNREAD = "unread";
// const LINK_STATUS_READING = "reading";

export default function LinkCheckbox(props) {
  const { _id, status, isOptimistic, isDisabled } = props;
  const prevLink = GetOneLinkFromCache(_id);

  const [updateLink] = useMutation(UPDATE_LINK, {
    update(cache, { data: { updateLink } }) {
      UpdateLinkCache({ prevLink, link: updateLink });
    },
  });
  return (
    <CheckboxOpt
      isLoading={isOptimistic}
      isChecked={status === LINK_STATUS_READ}
      isDisabled={isDisabled}
      onChange={() => {
        const newStatus =
          status === LINK_STATUS_UNREAD ? LINK_STATUS_READ : LINK_STATUS_UNREAD;
        const cachedLink = GetOneLinkFromCache(_id);
        updateLink({
          variables: {
            _id: _id,
            status: newStatus,
          },
          optimisticResponse: {
            updateLink: {
              ...cachedLink,
              status: newStatus,
              isOptimistic: true,
            },
          },
        });
      }}
    />
  );
}
