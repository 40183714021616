import React from "react";
import styled from "styled-components";
import TaskDate from "./TaskDate";
import ContextItem from "./ContextItem";
import { AlignLeftOutlined, NumberOutlined } from "@ant-design/icons";

export const StyledContext = styled.div`
  padding-left: 30px;
  padding: ${(props) => `0 35px ${props.theme.grid.quarter}px`};
`;

export default function TaskContext(props) {
  const { task } = props;
  const showOrder = false;

  return (
    <StyledContext onClick={props.onClick}>
      {showOrder && (
        <ContextItem icon={<NumberOutlined />} isActive={true}>
          {task.localPos}
        </ContextItem>
      )}
      <ContextItem icon={<AlignLeftOutlined />} isActive={!!task.desc} />
      {!task.isDone ? (
        <React.Fragment>
          {/* {showScheduledDate && ( */}
          <TaskDate
            type="scheduled"
            date={task.scheduledDate}
            noBg
            isActive={!!task.scheduledDate}
          />
          {/* )} */}
          {task.dueDate && <TaskDate type="due" date={task.dueDate} noBg />}
        </React.Fragment>
      ) : (
        <TaskDate type="completed" date={task.completedAt} noBg />
      )}
    </StyledContext>
  );
}
