import React, { useEffect, useCallback } from "react";
import { Form, Input } from "antd";
import { CREATE_TASK, InsertTaskIntoCache } from "../data/queries";
import { guidGenerator } from "../helpers";
import styled from "styled-components";
import TaskCheckbox from "./TaskCheckbox";
import { useMutation } from "@apollo/react-hooks";
import { useInlineActions } from "../stores/InlineStore";
import { useInlineDraftStore } from "../stores/InlineDraftStore";
import { useChunkActions } from "../stores/ChunkStore";

const StyledTaskForm = styled.div`
  width: 100%;
`;

const StyledTaskFormSummary = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.colors.N0};
  border: 1px solid ${(props) => props.theme.colors.N80};
  border-radius: 4px;
  }}

  display: flex;
  flex-wrap: nowrap;
  padding: 0 ${(props) => props.theme.grid.half + props.theme.grid.quarter}px 0
    ${(props) => props.theme.grid.quarter}px;

  transition: all 0.3s;
  box-shadow: 0 0 10px transparent;

  &:hover {
    border: 1px solid #40a9ff;
  }
  &:focus-within {
    border: 1px solid #40a9ff;
    outline: none;
    box-shadow: 0 0 10px #9ecaed;
  }

  > div {
    width: 100%;
  }

  .ant-form-item-control {
    line-height: 0;
  }

  input {
    height: 21px;
    padding: 0;
    border: 0;
    background-color: transparent;
    outline: none;

    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
      border-color: transparent;
    }
  }
`;

export function TaskInlineFormWrapper(props) {
  const { localPos, inlineId, applyFields, chunkInfo } = props;
  const [createTask] = useMutation(CREATE_TASK);
  const [, inlineStoreActions] = useInlineActions();
  const [, chunkActions] = useChunkActions();
  const [
    inlineDraftStoreState,
    inlineDraftStoreActions,
  ] = useInlineDraftStore();
  const [form] = Form.useForm();

  const escFunction = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        inlineStoreActions.close(inlineId);
      }
    },
    [inlineStoreActions, inlineId]
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  useEffect(() => {
    form.setFieldsValue({
      name: inlineDraftStoreState.draftName,
    });
  }, [inlineDraftStoreState, form]);

  const onSuccess = () => {
    console.log("success asdfas");
    // inlineStoreActions.close(inlineId);
  };

  const onCancel = () => {
    console.log("cancel");
    inlineStoreActions.close(inlineId);
  };

  const onChange = (event) => {
    inlineDraftStoreActions.setDraftName(event.target.value);
  };

  // const propTask = {
  //   localPos: localPos,
  //   // scheduledDate: scheduledDate,
  //   ...applyFields,

  // };

  const onFinish = (values) => {
    let task = {
      ...applyFields,
      __typename: "Task",
      _id: guidGenerator(),
      name: inlineDraftStoreState.draftName,
      localPos: localPos,
    };

    const simulatedSetTasks = InsertTaskIntoCache({
      task: task,
      chunkInfo: chunkInfo,
      simulate: true,
    });

    task.localPos = simulatedSetTasks.find((t) => t._id === task._id).localPos;
    delete task._id;

    inlineDraftStoreActions.setDraftName("");

    const optimisticResponse = {
      __typename: "Mutation",
      createTask: {
        _id: guidGenerator(),
        name: "",
        desc: null,
        scheduledDate: null,
        dueDate: null,
        localPos: null,
        completedAt: null,
        createdAt: Date.now(),
        userId: "123456",
        isDone: false,
        isOptimistic: true,
        isSoftRemove: false,
        __typename: "Task",
        ...task,
      },
    };

    createTask({
      variables: task,
      optimisticResponse,
      update: (cache, { data }) => {
        InsertTaskIntoCache({
          task: { ...data.createTask, localPos: localPos }, // use the chunk localPos, not the daypos
          chunkInfo: chunkActions.getChunkFromTask(data.createTask),
        });
      },
    });

    onSuccess();
  };

  return (
    <StyledTaskForm>
      <Form
        form={form}
        hideRequiredMark={true}
        onFinish={onFinish}
        // initialValues={{
        //   name: propTask.name,
        // }}
      >
        <StyledTaskFormSummary>
          <TaskCheckbox isDisabled />
          <Form.Item
            name="name"
            style={{ marginBottom: 0 }}
            rules={[{ required: true, message: "Summary is required" }]}
          >
            <Input
              placeholder="What will you do?"
              autoComplete="off"
              autoFocus
              onBlur={onCancel}
              onChange={onChange}
            />
          </Form.Item>
        </StyledTaskFormSummary>
      </Form>
    </StyledTaskForm>
  );
}
