import React from "react";
import styled from "styled-components";
import {
  DELETE_LINKQUOTE,
  RemoveLinkQuoteFromCache,
  GetOneLinkQuoteFromCache,
} from "../queries/LinkQueries";
import { useMutation } from "@apollo/react-hooks";
import { LoadingOutlined, DeleteOutlined } from "@ant-design/icons";

const StyledDeleteButton = styled.div`
  cursor: pointer;
  display: inline-block;
  margin-left: ${(props) => props.theme.grid.quarter}px;
  padding: ${(props) => props.theme.grid.tiny}px
    ${(props) => props.theme.grid.quarter}px;
  border-radius: 4px;
  transition: all 0.2s;
  &:hover {
    background-color: ${(props) => props.theme.colors.R400};
    color: ${(props) => props.theme.colors.N0};
  }
`;

const StyledSpinner = styled.div`
  display: inline-block;
  width: 22px;
  height: 22px;

  margin-left: ${(props) => props.theme.grid.quarter}px;
`;

export default function LinkQuoteDeleteButton(props) {
  const { _id, isLoading } = props;

  const [deleteLinkQuote] = useMutation(DELETE_LINKQUOTE, {
    update(cache, { data: { deleteLinkQuote } }) {
      RemoveLinkQuoteFromCache(deleteLinkQuote);
    },
  });

  if (isLoading) {
    return (
      <StyledSpinner>
        <LoadingOutlined style={{ color: "grey" }} />
      </StyledSpinner>
    );
  }

  return (
    <StyledDeleteButton
      onClick={() => {
        deleteLinkQuote({
          variables: { _id: _id },
          optimisticResponse: {
            deleteLinkQuote: { ...GetOneLinkQuoteFromCache(_id) },
          },
        });
      }}
    >
      <DeleteOutlined />
    </StyledDeleteButton>
  );
}
