import React from "react";
import styled from "styled-components";
import LinkQuoteItem from "./LinkQuoteItem";
import LinkQuoteForm from "./LinkQuoteForm";

const StyledQuoteItems = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
  margin-bottom: ${props => props.theme.grid.quarter}px;
`;

const StyledLinkQuotesList = styled.div`
  width: 100%;
`;

export default function LinkQuotesList(props) {
  const { linkId, quotes } = props;

  return (
    <StyledLinkQuotesList>
      {quotes.length > 0 && (
        <StyledQuoteItems>
          {quotes.map((q, i) => {
            return <LinkQuoteItem {...q} key={i} />;
          })}
        </StyledQuoteItems>
      )}
      <LinkQuoteForm linkId={linkId} />
    </StyledLinkQuotesList>
  );
}
