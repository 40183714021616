import React from "react";
import styled from "styled-components";
import { LoadingOutlined } from "@ant-design/icons";

export const StyledNavItem = styled.div`
  border-radius: ${(props) => props.theme.borderRadius};
  padding: ${(props) => props.theme.grid.tiny}px
    ${(props) => props.theme.grid.quarter}px;
  transition: all 0.2s;
  display: inline-block;

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.N20};
    color: ${(props) => props.theme.colors.G400};
  }

  > div {
    margin-right: ${(props) => props.theme.grid.quarter}px;
    display: inline-block;
  }

  a {
    color: inherit;
    transition: all 0.2s;
    width: 100%;
    display: inline-block;
  }

  .nav-item-active {
    color: ${(props) => props.theme.colors.G400};
    text-decoration: underline;
    text-decorationcolor: ${(props) => props.theme.colors.G400};
  }
`;

export default function NavItem(props) {
  const { onClick, icon, isLoading, children } = props;
  return (
    <StyledNavItem
      onClick={() => {
        if (typeof onClick === "function") {
          onClick();
        }
      }}
    >
      {icon && <div>{isLoading ? <LoadingOutlined /> : icon}</div>}

      {children}
    </StyledNavItem>
  );
}
