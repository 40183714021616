import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { ApolloLink } from "apollo-link";
import moment from "moment-timezone";

if (!process.env.REACT_APP_LINK_URI) throw new Error('Link URI not specified');

const devTools = process.env.REACT_APP_DEV_TOOLS || false;
const linkUri = new URL('/.netlify/functions/graphql', process.env.REACT_APP_LINK_URI);

// const envDetails = {
//   dev: {
//     devTools: true,
//     linkUri: "http://localhost:9000/graphql",
//   },
//   prod: {
//     devTools: false,
//     // linkUri: "https://dailydo-graphql.herokuapp.com/graphql",
//     linkUri: "https://api.dailydo.xyz/.netlify/functions/graphql",
//   },
// };

// const env = JSON.parse(localStorage.getItem("devMode"))
//   ? envDetails.dev
//   : envDetails.prod;

// const httpLink = createHttpLink({
//   uri: "http://localhost:4000",
//   credentials: "same-origin"
// });
const httpLink = createHttpLink({
  // uri: "https://dailydo-graphql.herokuapp.com/graphql",
  uri: linkUri, //"http://localhost:4000/graphql",
  // uri: "https://afjjl8pj06.execute-api.us-west-2.amazonaws.com/Prod/graphql",
  credentials: "include",
  // fetchOptions: {
  //   mode: "no-cors"
  // },
  // headers: {
  //   Accept: "application/json",
  //   "Content-Type": "application/json"
  // }
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      timezone: moment.tz.guess(),
    },
  };
});

// TODO: Monkey-patching in a fix for an open issue suggesting that
// `readQuery` should return null or undefined if the query is not yet in the
// cache: https://github.com/apollographql/apollo-feature-requests/issues/1
const cache = new InMemoryCache();
cache.originalReadQuery = cache.readQuery;
cache.readQuery = (...args) => {
  try {
    return cache.originalReadQuery(...args);
  } catch (err) {
    return undefined;
  }
};

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    authLink.concat(httpLink),
  ]),
  cache,
  connectToDevTools: devTools,
});

export default client;
