import React from "react";
import moment from "moment-timezone";
import DraggableList from "./DraggableList";
import styled from "styled-components";
import DailyHabitsList from "./DailyHabitsList";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { withTheme } from "styled-components";
import AllDayEvents from "./AllDayEvents";
import Event from "./Event";
import { useChunksBySection } from "../stores/ChunkStore";
import taskFilterFuncs from "../data/taskfilterfuncs";

const StyledDay = styled.div`
  position: relative;
  & + & {
    margin-top: ${(props) => props.theme.topMargin}px;
  }
`; // empty here, but exported and referenced in DayGroup

const DayTitle = styled.h3`
  color: ${(props) => props.theme.eventTextColor};
`;

const StyledVerticalGrid = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: ${(props) => `${props.theme.grid.quarter}px`};
`;

// function Day(props) {
//   const tasks = props.tasks || [];
//   if (props.hideIfEmpty && tasks.length === 0) return <React.Fragment />;

//   return (
//     <StyledDay>
//       <DayContent {...props} />
//     </StyledDay>
//   );
// }

function Day(props) {
  const {
    // id,
    sectionId,
    sectionType,
    isLoading,
    startDateTime,
    // endDateTime,
    theme,
    title,
    tasks,
    // events,
    allDayEvents,
    dailyHabits,
    isDropDisabled,
    isCreateDisabled,
    hideTitle,
    hideIfEmpty,
    hideDuration,
    showTitleAsDuration,
  } = props;
  // let chunks = [];
  let chunkList = [];
  // let filteredEvents = [];

  let filteredAllDayEvents =
    (sectionType === "dateRange" &&
      allDayEvents &&
      allDayEvents.filter((event) =>
        moment(startDateTime).isBetween(
          event.startDate,
          event.endDate,
          "day",
          "[]"
        )
      )) ||
    [];
  // let filteredDailyHabits = [];
  let maxTasks = 0;
  const [chunksState] = useChunksBySection({
    sectionId,
  });

  if (isLoading) {
    return (
      <SkeletonTheme color={theme.taskBgHover} highlightColor={theme.contextBg}>
        <Skeleton width="100%" height="32px" count={5} />
      </SkeletonTheme>
    );
  }
  const filteredDailyHabits =
    (dailyHabits &&
      dailyHabits.filter(
        (dH) => startDateTime && moment(dH.date).isSame(startDateTime, "day")
      )) ||
    [];
  const sortedTasks = tasks || []; //props.tasks ? sortTasksByPos(props.tasks) : [];
  if (chunksState.chunks.length > 0) {
    chunksState.chunks.forEach((chunk) => {
      if (chunk.__typename !== "Event" && chunk.sectionType) {
        chunk.tasks = sortedTasks.filter(taskFilterFuncs(chunk));
        maxTasks = Math.max(chunk.tasks.length, maxTasks);
      }
    });

    chunkList = chunksState.chunks.map((item) => {
      if (item.__typename === "Event") {
        return <Event {...item} key={item._id} />;
      } else {
        return (
          <DraggableList
            key={item.id}
            chunkId={item.id}
            chunkInfo={item}
            duration={item.duration}
            hideDuration={hideDuration}
            showTitleAsDuration={showTitleAsDuration}
            title={title}
            items={item.tasks}
            showScheduledDate={props.showScheduledDate}
            scheduledDate={
              props.date ? moment(props.date, "YYYY-MM-DD").toISOString() : null
            }
            startDateTime={item.startDateTime}
            endDateTime={item.endDateTime}
            isCreateDisabled={isCreateDisabled}
            isDropDisabled={isDropDisabled}
          />
        );
      }
    });
  }
  if (maxTasks > 0 || !hideIfEmpty) {
    return (
      <StyledDay>
        {!hideTitle && <DayTitle>{title}</DayTitle>}
        <DailyHabitsList dailyHabits={filteredDailyHabits} />
        <AllDayEvents allDayEvents={filteredAllDayEvents} />
        <StyledVerticalGrid>{chunkList}</StyledVerticalGrid>
        {props.children}
      </StyledDay>
    );
  } else return null;
}

export default withTheme(Day);
