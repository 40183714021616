// import styled from "react-emotion";

export const lightTheme = {
  borderRadius: "4px",
  colors: {
    N900: "#091E42",
    N800: "#172B4D",
    N700: "#253858",
    N600: "#344563",
    N500: "#42526E",
    N400: "#505F79",
    N300: "#5E6C84",
    N200: "#6B778C",
    N100: "#7A869A",
    N90: "#8993A4",
    N80: "#97a0af",
    N70: "#A5ADBA",
    N60: "#B3BAC5",
    N50: "#C1C7D0",
    N40: "#DFE1E6",
    N30: "#EBECF0",
    N20: "#f4f5f7",
    N10: "#fbfbfb",
    N0: "#FFF",
    G500: "#006644",
    G400: "#388062",
    G300: "#36B37E",
    G200: "#57D9A3",
    G100: "#79F2C0",
    G75: "#ABF5D1",
    G50: "#e8f3ec",
    Y400: "#FF991F",
    R500: "#BF2600",
    R400: "#DE350B",
    R300: "#FF5630",
    R200: "#FF7452",
    B400: "#0052CC",
    B200: "#2684FF",
    B100: "#4C9AFF",
    B75: "#B3D4FF",
    B50: "#DEEBFF",
  },
  grid: { double: 40, full: 20, half: 10, quarter: 5, tiny: 2 },
};

export const darkTheme = {
  ...lightTheme,
  colors: {
    N900: "#FFFFF",
    N700: "#fbfbfb",
    N80: "#97a0af",

    N20: "#f4f5f7",
    N10: "#fbfbfb",
    N0: "#091E42",
    G400: "#e8f3ec",
    G50: "#388062",
  },
};
