import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import React from "react";

export default function Spinner(props) {
  return (
    <div style={{ textAlign: "center", marginTop: 20 }}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    </div>
  );
}
