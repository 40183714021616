import { createStore, createHook } from "react-sweet-state";
import client from "../client";
import { getWeekDays } from "../helpers";
import { message } from "antd";
import moment from "moment-timezone";

const Store = createStore({
  initialState: { weekDays: getWeekDays() },
  actions: {
    setWeekDays: weekDays => ({ setState }) => {
      setState({ weekDays });
    },
    checkReset: () => ({ setState, getState, actions }) => {
      const weekDays = getState().weekDays;
      if (weekDays.length) {
        if (!moment().isSame(weekDays[0], "day")) {
          console.log("day changed?");
          actions.setWeekDays(getWeekDays());
          client.resetStore().then(() => {
            message.success("Refreshed");
          });
        } else {
          // console.log("day hasn't changed");
        }
      }
    }
  }
});

export const useWeekdayStore = createHook(Store);
