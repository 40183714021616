import React from "react";
import { DELETE_TASK, RemoveTaskFromCache } from "../data/queries";
import { useMutation } from "@apollo/react-hooks";
import { Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useChunkActions } from "../stores/ChunkStore";

export default function TaskDeleteButton(props) {
  const { task, onSuccess } = props;
  const [, chunkActions] = useChunkActions();

  const [deleteTask] = useMutation(DELETE_TASK, {
    update: (cache, { data: { deleteTask } }) => {
      RemoveTaskFromCache({
        task: deleteTask,
        chunkInfo: chunkActions.getChunkFromTask(deleteTask),
      });
    },
    optimisticResponse: {
      __typename: "Mutation",
      deleteTask: {
        _id: task._id,
        isDone: task.isDone,
        scheduledDate: task.scheduledDate,
        __typename: "DeletedTask",
      },
    },
  });
  return (
    <Button
      onClick={() => {
        onSuccess();
        deleteTask({ variables: { _id: task._id } });
      }}
      icon={<DeleteOutlined />}
      type="danger"
      size="small"
      ghost
    >
      Delete
    </Button>
  );
}
