import gql from "graphql-tag";
import client from "../client";

// FRAGMENTS
let LinkHelpers = {};
let LinkQuoteHelpers = {};

LinkHelpers.fragments = {
  info: gql`
    fragment LinkInfo on Link {
      _id
      author
      createdAt
      domain
      numQuotes
      wordCount
      excerpt
      notes
      rating
      isOptimistic
      readAt
      status
      title
      url
      userId
    }
  `
};
LinkQuoteHelpers.fragments = {
  info: gql`
    fragment LinkQuoteInfo on LinkQuote {
      _id
      linkId
      quote
      isOptimistic
      createdAt
    }
  `
};

// QUERIES
export const GET_LINKS = gql`
  query links(
    $status: LinkStatus
    $orderBy: AllowedLinksSort
    $limit: Int
    $offset: Int
  ) {
    links(status: $status, orderBy: $orderBy, limit: $limit, offset: $offset) {
      ...LinkInfo
    }
  }
  ${LinkHelpers.fragments.info}
`;

export const GET_LINK = gql`
  query link($_id: String!) {
    link(_id: $_id) {
      ...LinkInfo
      quotes {
        ...LinkQuoteInfo
      }
    }
  }
  ${LinkHelpers.fragments.info}
  ${LinkQuoteHelpers.fragments.info}
`;

// MUTATIONS

export const CREATE_LINK = gql`
  mutation createLink($url: String!) {
    createLink(url: $url) {
      ...LinkInfo
    }
  }
  ${LinkHelpers.fragments.info}
`;

export const UPDATE_LINK = gql`
  mutation updateLink(
    $_id: String!
    $status: LinkStatus
    $title: String
    $excerpt: String
    $notes: String
    $rating: Int
    $url: String
    $wordCount: Int
  ) {
    updateLink(
      _id: $_id
      status: $status
      title: $title
      excerpt: $excerpt
      notes: $notes
      rating: $rating
      url: $url
      wordCount: $wordCount
    ) {
      ...LinkInfo
    }
  }
  ${LinkHelpers.fragments.info}
`;

export const DELETE_LINK = gql`
  mutation deleteLink($_id: String!) {
    deleteLink(_id: $_id) {
      ...LinkInfo
    }
  }
  ${LinkHelpers.fragments.info}
`;

export const CREATE_LINKQUOTE = gql`
  mutation createLinkQuote($linkId: String!, $quote: String!) {
    createQuoteByLinkId(linkId: $linkId, quote: $quote) {
      ...LinkQuoteInfo
    }
  }
  ${LinkQuoteHelpers.fragments.info}
`;

export const DELETE_LINKQUOTE = gql`
  mutation deleteLinkQuote($_id: String!) {
    deleteLinkQuote(_id: $_id) {
      ...LinkQuoteInfo
    }
  }
  ${LinkQuoteHelpers.fragments.info}
`;

// QUERY BUILDERS
export const GetLinksQuery = ({ status }) => {
  const statusVariables = {
    unread: {
      status: "unread",
      orderBy: "createdAt_DESC"
    },
    read: {
      status: "read",
      orderBy: "readAt_DESC",
      limit: 20
    }
  };

  return {
    query: GET_LINKS,
    variables: statusVariables[status]
  };
};

// CACHE MANIPULATORS

export const GetOneLinkFromCache = linkId => {
  return client.readFragment({
    fragment: LinkHelpers.fragments.info,
    fragmentName: "LinkInfo",
    id: `Link:${linkId}`
  });
};

export const InsertLinkIntoCache = link => {
  return InsertOrRemoveLinkFromCache({ link, action: "insert" });
};

export const RemoveLinkFromCache = link => {
  return InsertOrRemoveLinkFromCache({ link, action: "remove" });
};

export const InsertOrRemoveLinkFromCache = ({ link, action }) => {
  const isInsert = action === "insert";
  const statusLinks = client.readQuery({
    ...GetLinksQuery({ status: link.status })
  });
  if (statusLinks) {
    const newLinks = statusLinks.links.filter(l => l._id !== link._id);
    isInsert && newLinks.splice(0, 0, link);
    client.writeQuery({
      ...GetLinksQuery({ status: link.status }),
      data: {
        links: newLinks
      }
    });
  }
};

export const UpdateLinkCache = ({ prevLink, link }) => {
  prevLink && prevLink._id && RemoveLinkFromCache(prevLink);
  InsertLinkIntoCache(link);
};

export const GetOneLinkQuoteFromCache = linkQuoteId => {
  return client.readFragment({
    fragment: LinkQuoteHelpers.fragments.info,
    fragmentName: "LinkQuoteInfo",
    id: `LinkQuote:${linkQuoteId}`
  });
};

export const InsertLinkQuoteIntoCache = linkQuote => {
  return InsertOrRemoveLinkQuoteFromCache({ linkQuote, action: "insert" });
};

export const RemoveLinkQuoteFromCache = linkQuote => {
  return InsertOrRemoveLinkQuoteFromCache({ linkQuote, action: "remove" });
};

// export const UpdateLinkQuoteCache = ({ prevLinkQuote, linkQuote }) => {
//   prevLinkQuote && prevLinkQuote._id && RemoveLinkQuoteFromCache(prevLinkQuote);
//   InsertLinkQuoteIntoCache(linkQuote);
// };

export const InsertOrRemoveLinkQuoteFromCache = ({ linkQuote, action }) => {
  const isInsert = action === "insert";
  const { link } = client.readQuery({
    query: GET_LINK,
    variables: { _id: linkQuote.linkId }
  });
  if (link) {
    const newQuotes = link.quotes.filter(lq => lq._id !== linkQuote._id);
    isInsert && newQuotes.push(linkQuote); //newQuotes.splice(0, 0, linkQuote);
    link.quotes = newQuotes;
    link.numQuotes = link.quotes.length;
    console.log(link);
    client.writeQuery({
      query: GET_LINK,
      variables: { _id: linkQuote.linkId },
      data: {
        link
      }
    });
  }
};
